export const AskAsatoStubDataTC001 = {
  QueryTC001: [
    {
      applicationName: 'Salesforce Drivewyze',
      DurationSec: 8
    },
    {
      applicationName: 'Adobe Creative Cloud',
      DurationSec: 36
    },
    {
      applicationName: 'TriNet Expense',
      DurationSec: 66
    },
    {
      applicationName: 'Jira',
      DurationSec: 74
    },
    {
      applicationName: 'Slack',
      DurationSec: 102
    }
  ],
  head: ['Application Name', 'Duration (in sec)'],
  JsonQueryData: [
    `{
  "core_customers": ["id", "customername"],
  "tenants_usage": ["applicationid",
   "duration", "usagedate"],
  "core_applications": ["id", "applicationname"]
}`
  ],
  SqlQuery: [
    `SELECT 
    ca.applicationname AS "Application Name", 
    SUM(tu.duration) AS "Duration (in sec)" 
FROM 
    core.customers cc 
JOIN 
    tenants.usage tu ON cc.id = tu.customerid 
JOIN 
    core.applications ca ON tu.applicationid = ca.id 
WHERE 
    cc.customername = 'Drivewyze' 
    AND tu.usagedate >= date_trunc('quarter', CURRENT_DATE) 
GROUP BY 
    ca.applicationname 
ORDER BY 
    SUM(tu.duration) ASC 
LIMIT 5;`
  ]
};

export const AskAsatoStubDataTC002 = {
  QueryTC002: [
    {
      applicationName: 'Segment',
      cost: '$29,500.00',
      systemsowner: 'IT',
      businessowner: 'PM'
    },
    {
      applicationName: 'Qtest',
      cost: '$24,750.00',
      systemsowner: 'IT',
      businessowner: 'QA'
    },
    {
      applicationName: 'Miro',
      cost: '$22,140.00',
      systemsowner: 'IT',
      businessowner: 'Dev, PM'
    },
    {
      applicationName: 'IPSens',
      cost: '$21,000.00',
      systemsowner: 'Enrique',
      businessowner: 'Service Desk'
    },
    {
      applicationName: 'MoEngage',
      cost: '$20,400.00',
      systemsowner: 'IT',
      businessowner: 'PM'
    },
    {
      applicationName: 'Jira',
      cost: '$20,027.10',
      systemsowner: 'IT',
      businessowner: 'IT'
    },
    {
      applicationName: 'Low Clearance',
      cost: '$17,000.00',
      systemsowner: 'IT',
      businessowner: 'PM'
    },
    {
      applicationName: 'GitLab Premium',
      cost: '$16,644.00',
      systemsowner: 'IT',
      businessowner: 'IT'
    },
    {
      applicationName: 'Confluence',
      cost: '$13,293.00',
      systemsowner: 'IT',
      businessowner: 'IT'
    },
    {
      applicationName: 'Chameleon',
      cost: '$11,000.00',
      systemsowner: 'IT',
      businessowner: 'PM'
    },
    {
      applicationName: 'Mixpanel',
      cost: '$9,072.01',
      systemsowner: 'IT',
      businessowner: 'PM'
    },
    {
      applicationName: 'Visual Studio/MSDN',
      cost: '$6,480.00',
      systemsowner: 'IT',
      businessowner: 'Dev'
    },
    {
      applicationName: 'SurveyMonkey',
      cost: '$6,247.50',
      systemsowner: 'IT',
      businessowner: 'IT'
    },
    {
      applicationName: 'Hotjar',
      cost: '$5,868.00',
      systemsowner: 'IT',
      businessowner: 'PM'
    },
    {
      applicationName: 'ReadMe',
      cost: '$4,788.00',
      systemsowner: 'Dev',
      businessowner: 'PM'
    },
    {
      applicationName: 'Airbrake',
      cost: '$3,588.00',
      systemsowner: 'Dev',
      businessowner: 'Dev'
    },
    {
      applicationName: 'Studio3T',
      cost: '$2,860.00',
      systemsowner: 'DEV',
      businessowner: 'Dev'
    },
    {
      applicationName: 'Raygun',
      cost: '$2,388.00',
      systemsowner: 'Dev',
      businessowner: 'Dev'
    },
    {
      applicationName: 'ScriptRunner',
      cost: '$2,083.20',
      systemsowner: 'IT',
      businessowner: 'Dev'
    },
    {
      applicationName: 'TeamRetro',
      cost: '$1,800.14',
      systemsowner: 'IT',
      businessowner: 'Dev'
    },
    {
      applicationName: 'Figma',
      cost: '$1,620.00',
      systemsowner: 'IT',
      businessowner: 'PM'
    },
    {
      applicationName: 'Docker',
      cost: '$1,500.00',
      systemsowner: 'IT',
      businessowner: 'Dev'
    },
    {
      applicationName: 'SendGrid',
      cost: '$1,133.40',
      systemsowner: 'DEV',
      businessowner: 'Dev'
    },
    {
      applicationName: 'DataGrip',
      cost: '$200.00',
      systemsowner: 'IT',
      businessowner: 'Dev'
    },
    {
      applicationName: 'Sublime TextSublime Merge',
      cost: '$75.00',
      systemsowner: 'IT',
      businessowner: 'Dev'
    },
    {
      applicationName: 'Firebase',
      cost: '$0.00',
      systemsowner: 'PM',
      businessowner: 'PM'
    },
    {
      applicationName: 'Pycharm',
      cost: '$0.00',
      systemsowner: 'DEV',
      businessowner: 'Dev'
    },
    {
      applicationName: 'Braintree',
      cost: '$0.00',
      systemsowner: 'Steve Brown',
      businessowner: 'CX'
    },
    {
      applicationName: 'Mantis',
      cost: '$0.00',
      systemsowner: 'DIS Dev',
      businessowner: 'DIS Dev'
    },
    {
      applicationName: 'Postman',
      cost: '$0.00',
      systemsowner: 'DEV',
      businessowner: 'Dev'
    },
    {
      applicationName: 'Usability Hub',
      cost: '$0.00',
      systemsowner: 'IT',
      businessowner: 'UX'
    }
  ],
  head: ['Application Name', 'Cost', 'systems owner', 'business owner'],
  JsonQueryData: [
    `{
  "core_customers": ["id", "customername"],
  "core_applications": ["applicationname", "category", "systemsowner", "businessowner"],
  "tenants_licenses": ["applicationid", "customerid", "totalannualcost"]
}`
  ],
  SqlQuery: [
    `SELECT 
    a.applicationname AS "Application Name", 
    l.totalannualcost AS "Cost", 
    a.systemsowner, 
    a.businessowner 
FROM 
    core.applications a 
JOIN 
    tenants.licenses l ON a.id = l.applicationid 
JOIN 
    core.customers c ON l.customerid = c.id 
WHERE 
    c.customername = 'Drivewyze' 
    AND a.category = 'Product Development' 
ORDER BY 
    l.totalannualcost DESC;`
  ]
};

export const AskAsatoStubDataTC003 = {
  QueryTC003: [
    {
      'Application Name': 'Keeper',
      'Renewal Date': '2024-03-08 00:00:00',
      'Invoice Period': 'Annual'
    },
    {
      'Application Name': 'Impartner',
      'Renewal Date': '2024-03-24 00:00:00',
      'Invoice Period': 'Annual'
    },
    {
      'Application Name': 'RigDigIntegration Package',
      'Renewal Date': '2024-03-15 00:00:00',
      'Invoice Period': 'Annual'
    },
    {
      'Application Name': 'Avalara',
      'Renewal Date': '2024-03-20 00:00:00',
      'Invoice Period': 'Annual'
    }
  ],
  head: ['Application Name', 'Renewal Date', 'Invoice Period'],
  JsonQueryData: [
    `{
  "core_customers": ["id", "customername"],
  "core_applications": ["applicationname"],
  "tenants_licenses": ["applicationid", "customerid", "renewaldate", "invoiceperiod"]
}`
  ],
  SqlQuery: [
    `SELECT 
    a.applicationname AS "Application Name", 
    l.renewaldate AS "Renewal Date", 
    l.invoiceperiod AS "Invoice Period" 
FROM 
    core.applications a 
JOIN 
    tenants.licenses l ON a.id = l.applicationid 
JOIN 
    core.customers c ON c.id = l.customerid 
WHERE 
    c.customername = 'Drivewyze' 
    AND EXTRACT(YEAR FROM l.renewaldate) = EXTRACT(YEAR FROM CURRENT_DATE + INTERVAL '1 month') 
    AND EXTRACT(MONTH FROM l.renewaldate) = EXTRACT(MONTH FROM CURRENT_DATE + INTERVAL '1 month');`
  ]
};
export const AskAsatoStubDataTC004 = {
  QueryTC004: [
    {
      id: 3950,
      displayname: 'Eric Reichle',
      email: 'ereichle@drivewyze.com',
      usage_hours: 16
    },
    {
      id: 4002,
      displayname: 'Jason Baxter',
      email: 'jbaxter@drivewyze.com',
      usage_hours: 14
    },
    {
      id: 1782,
      displayname: 'Justin Tysdal',
      email: 'jtysdal@drivewyze.com',
      usage_hours: 12
    },
    {
      id: 1884,
      displayname: 'Dean Melnychuk',
      email: 'dmelnychuk@drivewyze.com',
      usage_hours: 4
    },
    {
      id: 1871,
      displayname: 'Kevin Forbes',
      email: 'kforbes@drivewyze.com',
      usage_hours: 3
    }
  ],
  head: ['id', 'display name', 'email', 'usage hours'],
  JsonQueryData: [
    `  {
  "core_customers": ["id", "customername"],
  "tenants_users": ["id", "displayname", "employeeid", "email"],
  "tenants_usage": ["userid", "customerid", "duration", "usagedate"]
}`
  ],
  SqlQuery: [
    `SELECT 
    tu.id, 
    tu.displayname, 
    tu.email, 
    SUM(tu2.duration)/3600 AS usage_hours 
FROM 
    tenants.users tu 
INNER JOIN 
    core.customers cc ON tu.customerid = cc.id 
INNER JOIN 
    tenants.usage tu2 ON tu.id = tu2.userid 
WHERE 
    cc.customername = 'Drivewyze' 
    AND tu2.usagedate >= date_trunc('month', CURRENT_DATE - INTERVAL '2 month') 
    AND tu2.usagedate < date_trunc('month', CURRENT_DATE) 
GROUP BY 
    tu.id, 
    tu.displayname, 
    tu.email 
ORDER BY 
    usage_hours DESC 
LIMIT 5;`
  ]
};

export const AskAsatoStubDataTC005 = {
  QueryTC005: [
    {
      applicationname: 'Miro',
      totalannualcost: '$22,140.00',
      monthlycostperuser: '$22.50'
    },
    {
      applicationname: 'Adobe Creative Cloud',
      totalannualcost: '$5,099.40',
      monthlycostperuser: '$84.99'
    }
  ],
  head: ['application name', 'total annual cost', 'monthly cost per user'],
  JsonQueryData: [
    `  {
  "core_customers": ["id", "customername"],
  "core_applications": ["id", "applicationname"],
  "tenants_licenses": ["applicationid", "customerid", "totalannualcost", "monthlycostperuser"]
}`
  ],
  SqlQuery: [
    `SELECT 
    a.applicationname, 
    l.totalannualcost, 
    l.monthlycostperuser 
FROM 
    core.applications a 
JOIN 
    tenants.licenses l ON a.id = l.applicationid 
JOIN 
    core.customers c ON l.customerid = c.id 
WHERE 
    c.customername = 'Drivewyze' 
    AND (a.applicationname = 'Adobe Creative Cloud' OR a.applicationname = 'Miro');`
  ]
};

export const ASK_ASATO_DEMO_STUB = {
  'what is the total count of paid and unpaid applications used in our enterprise in the last month?': {
    dataframe: [
      {
        Paid: 67,
        Unpaid: 379
      }
    ],
    hitsPerPage: 10,
    page_number: 0,
    pagination_urls: {
      page_0: 'http://127.0.0.1:5000/api/v1/get-table?page=0'
    },
    totalCount: 1,
    totalPages: 1
  },
  'which 10 applications have the highest number of users per app in the last 90 days?': {
    dataframe: [
      {
        appName: 'Microsoft Outlook',
        userCount: 2582
      },
      {
        appName: 'MS teams',
        userCount: 2465
      },
      {
        appName: 'Microsoft Word',
        userCount: 1899
      },
      {
        appName: 'Microsoft Excel',
        userCount: 1705
      },
      {
        appName: 'Microsoft PowerPoint',
        userCount: 1254
      },
      {
        appName: 'UKG (Ultimate Kronos Group)',
        userCount: 656
      },
      {
        appName: 'RingCentral',
        userCount: 381
      },
      {
        appName: 'Microsoft OneNote',
        userCount: 335
      },
      {
        appName: 'Smartsheet',
        userCount: 297
      },
      {
        appName: 'Freshservice',
        userCount: 273
      }
    ],
    hitsPerPage: 10,
    page_number: 0,
    pagination_urls: {
      page_0: 'http://127.0.0.1:5000/api/v1/get-table?page=0'
    },
    totalCount: 10,
    totalPages: 1
  },
  'provide a list of the 10 least utilized paid applications, sorted by employee usage over the past quarter.': {
    dataframe: [
      {
        appName: 'SiriusXM',
        utilization: '0.6%'
      },
      {
        appName: 'LastPass',
        utilization: '5.0%'
      },
      {
        appName: 'Adobe Stock',
        utilization: '5.5%'
      },
      {
        appName: 'Adobe Photoshop Lightroom',
        utilization: '5.5%'
      },
      {
        appName: 'Microsoft OneNote',
        utilization: '0.3%'
      },
      {
        appName: 'Adobe Fonts',
        utilization: '6.6%'
      },
      {
        appName: 'RingCentral',
        utilization: '6.6%'
      },
      {
        appName: 'GoToMyPC',
        utilization: '5.0%'
      },
      {
        appName: 'Adobe Document Cloud',
        utilization: '7.7%'
      },
      {
        appName: 'Adobe Acrobat Sign',
        utilization: '8.8%'
      }
    ],
    hitsPerPage: 10,
    page_number: 0,
    pagination_urls: {
      page_0: 'http://127.0.0.1:5000/api/v1/get-table?page=0'
    },
    totalCount: 10,
    totalPages: 1
  },
  'which applications have their license renewals due in the next quarter?': {
    dataframe: [
      {
        date: '2024-09-10',
        name: 'DocuSign - eSignature Access',
        type: 'licenseProcessor'
      },
      {
        date: '2024-08-27',
        name: 'GotoMyPC',
        type: 'licenseProcessor'
      },
      {
        date: '2024-10-25',
        name: 'Smartsheet',
        type: 'licenseProcessor'
      },
      {
        date: '2024-10-27',
        name: 'LastPass',
        type: 'licenseProcessor'
      }
    ],
    hitsPerPage: 10,
    page_number: 0,
    pagination_urls: {
      page_0: 'http://127.0.0.1:5000/api/v1/get-table?page=0'
    },
    totalCount: 4,
    totalPages: 1
  },
  'which 10 applications have the highest usage per app in the last 90 days?': {
    dataframe: [
      {
        appName: 'RingCentral',
        'usage (in GB)': 36.33
      },
      {
        appName: 'UKG (Ultimate Kronos Group)',
        'usage (in GB)': 18.1
      },
      {
        appName: 'Smartsheet',
        'usage (in GB)': 13.31
      },
      {
        appName: 'Dropbox',
        'usage (in GB)': 8.97
      },
      {
        appName: 'Zoom Video Communications',
        'usage (in GB)': 7.24
      },
      {
        appName: 'Freshdesk',
        'usage (in GB)': 6.4
      },
      {
        appName: 'Adobe Developer',
        'usage (in GB)': 3.72
      },
      {
        appName: 'Adobe',
        'usage (in GB)': 2.9
      },
      {
        appName: 'Cisco Webex',
        'usage (in GB)': 2.38
      },
      {
        appName: 'Oracle NetSuite',
        'usage (in GB)': 1.99
      }
    ],
    hitsPerPage: 10,
    page_number: 0,
    pagination_urls: {
      page_0: 'http://127.0.0.1:5000/api/v1/get-table?page=0'
    },
    totalCount: 10,
    totalPages: 1
  },
  'which are top risky apps being used in last 90 days?': {
    dataframe: [
      {
        appName: 'MongoDB',
        Cost: '$58,175',
        'User Count': 30,
        Usage: '102,691,742 bytes'
      },
      {
        appName: 'Baidu',
        Cost: '$0',
        'User Count': 3,
        Usage: '177,181 bytes'
      },
      {
        appName: 'CVS',
        Cost: '$0',
        'User Count': 14,
        Usage: '33,820,877 bytes'
      },
      {
        appName: 'JobAdder',
        Cost: '$0',
        'User Count': 1,
        Usage: '284,111 bytes'
      },
      {
        appName: 'Coupons',
        Cost: '$0',
        'User Count': 1,
        Usage: '504,737 bytes'
      },
      {
        appName: 'AddThis',
        Cost: '$0',
        'User Count': 33,
        Usage: '1,960,866 bytes'
      }
    ],
    hitsPerPage: 10,
    page_number: 0,
    pagination_urls: {
      page_0: 'http://127.0.0.1:5000/api/v1/get-table?page=0'
    },
    totalCount: 6,
    totalPages: 1
  },
  'what are top over provisioned clinics?': {
    dataframe: [
      {
        Address: '2505 Dallas Hwy, Marietta, GA, 30064',
        'Audit Status': '',
        'Buffer Range': '09-14',
        'Ideal Asset Count': 11,
        'Inventory Asset Count': 40,
        'Moving median traffic': 42,
        Organization: 'Trinity Health',
        'Site Code': 'WCB',
        'Site Name': 'West Cobb',
        State: 'GA',
        'Tech Provisioning': 'Over Provisioned',
        Tier: 3,
        'Total assets Cost': 42880,
        Variance: 26,
        'Variance %': 236,
        'Variance Tier': '>100%',
        'Wastage Amount': 27872
      },
      {
        Address: '6470 Orchard Lake Road, West Bloomfield, MI, 48322',
        'Audit Status': '',
        'Buffer Range': '06-09',
        'Ideal Asset Count': 7,
        'Inventory Asset Count': 20,
        'Moving median traffic': 21,
        Organization: 'LifePoint Health',
        'Site Code': 'WBF',
        'Site Name': 'West Bloomfield',
        State: 'MI',
        'Tech Provisioning': 'Over Provisioned',
        Tier: 1,
        'Total assets Cost': 21440,
        Variance: 11,
        'Variance %': 157,
        'Variance Tier': '>100%',
        'Wastage Amount': 11792
      },
      {
        Address: '11402 Anderson Rd, Suite A, Greenville, SC, 29611',
        'Audit Status': '',
        'Buffer Range': '09-14',
        'Ideal Asset Count': 11,
        'Inventory Asset Count': 30,
        'Moving median traffic': 49,
        Organization: 'HCA HealthCare',
        'Site Code': 'PWD',
        'Site Name': 'Powdersville',
        State: 'SC',
        'Tech Provisioning': 'Over Provisioned',
        Tier: 3,
        'Total assets Cost': 32160,
        Variance: 16,
        'Variance %': 145,
        'Variance Tier': '>100%',
        'Wastage Amount': 17152
      },
      {
        Address: '2701 Holcomb Bridge Road, Alpharetta, GA, 30022',
        'Audit Status': 'Complete',
        'Buffer Range': '06-09',
        'Ideal Asset Count': 7,
        'Inventory Asset Count': 19,
        'Moving median traffic': 28,
        Organization: 'Trinity Health',
        'Site Code': 'HBR',
        'Site Name': 'Holcomb Bridge/East Roswell',
        State: 'GA',
        'Tech Provisioning': 'Over Provisioned',
        Tier: 1,
        'Total assets Cost': 20368,
        Variance: 10,
        'Variance %': 143,
        'Variance Tier': '>100%',
        'Wastage Amount': 10720
      },
      {
        Address: '1298 Woodruff Rd, Greenville, SC, 29607',
        'Audit Status': '',
        'Buffer Range': '06-09',
        'Ideal Asset Count': 7,
        'Inventory Asset Count': 19,
        'Moving median traffic': 25,
        Organization: 'HCA HealthCare',
        'Site Code': 'GWR',
        'Site Name': 'Greenville - Woodruff',
        State: 'SC',
        'Tech Provisioning': 'Over Provisioned',
        Tier: 1,
        'Total assets Cost': 20368,
        Variance: 10,
        'Variance %': 143,
        'Variance Tier': '>100%',
        'Wastage Amount': 10720
      },
      {
        Address: '905 Verdae Blvd, Suite 101, Greenville, SC, 29607',
        'Audit Status': '',
        'Buffer Range': '09-14',
        'Ideal Asset Count': 11,
        'Inventory Asset Count': 29,
        'Moving median traffic': 52,
        Organization: 'HCA HealthCare',
        'Site Code': 'GVV',
        'Site Name': 'Verdae (Greenville)',
        State: 'SC',
        'Tech Provisioning': 'Over Provisioned',
        Tier: 3,
        'Total assets Cost': 31088,
        Variance: 15,
        'Variance %': 136,
        'Variance Tier': '>100%',
        'Wastage Amount': 16080
      },
      {
        Address: '19760 Haggerty Rd, Livonia, MI, 48152',
        'Audit Status': 'Complete',
        'Buffer Range': '06-09',
        'Ideal Asset Count': 7,
        'Inventory Asset Count': 18,
        'Moving median traffic': 24,
        Organization: 'LifePoint Health',
        'Site Code': 'HTS',
        'Site Name': 'Haggerty Square',
        State: 'MI',
        'Tech Provisioning': 'Over Provisioned',
        Tier: 1,
        'Total assets Cost': 19296,
        Variance: 9,
        'Variance %': 129,
        'Variance Tier': '>100%',
        'Wastage Amount': 9648
      },
      {
        Address: '6063 Peachtree Parkway, Suite 201A, Peachtree Corners, GA, 30092',
        'Audit Status': 'Complete',
        'Buffer Range': '06-09',
        'Ideal Asset Count': 7,
        'Inventory Asset Count': 18,
        'Moving median traffic': 23,
        Organization: 'Trinity Health',
        'Site Code': 'NOR',
        'Site Name': 'Norcross',
        State: 'GA',
        'Tech Provisioning': 'Over Provisioned',
        Tier: 1,
        'Total assets Cost': 19296,
        Variance: 9,
        'Variance %': 129,
        'Variance Tier': '>100%',
        'Wastage Amount': 9648
      },
      {
        Address: '50964 Gratiot Ave, Chesterfield Township, MI, 48051',
        'Audit Status': '',
        'Buffer Range': '06-09',
        'Ideal Asset Count': 7,
        'Inventory Asset Count': 18,
        'Moving median traffic': 29,
        Organization: 'LifePoint Health',
        'Site Code': 'CTF',
        'Site Name': 'Chesterfield',
        State: 'MI',
        'Tech Provisioning': 'Over Provisioned',
        Tier: 1,
        'Total assets Cost': 19296,
        Variance: 9,
        'Variance %': 129,
        'Variance Tier': '>100%',
        'Wastage Amount': 9648
      },
      {
        Address: '3515 Camp Creek Parkway, East Point, GA, 30344',
        'Audit Status': 'Complete',
        'Buffer Range': '09-14',
        'Ideal Asset Count': 11,
        'Inventory Asset Count': 28,
        'Moving median traffic': 58,
        Organization: 'Trinity Health',
        'Site Code': 'EPT',
        'Site Name': 'East Point',
        State: 'GA',
        'Tech Provisioning': 'Over Provisioned',
        Tier: 3,
        'Total assets Cost': 30016,
        Variance: 14,
        'Variance %': 127,
        'Variance Tier': '>100%',
        'Wastage Amount': 15008
      }
    ],
    hitsPerPage: 10,
    page_number: 0,
    pagination_urls: {
      page_0: 'http://127.0.0.1:5000/api/v1/get-table?page=0'
    },
    totalCount: 10,
    totalPages: 1
  },
  'what is asset drift status across organization?': {
    dataframe: [
      {
        category: 'Trinity Health',
        unchanged: 1308,
        unknown: 511,
        drifted: 29
      },
      {
        category: 'HCA HealthCare',
        unknown: 24,
        unchanged: 219,
        drifted: 5
      },
      {
        category: 'Tenet HealthCare',
        unchanged: 175,
        unknown: 33,
        drifted: 14
      },
      {
        category: 'LifePoint Health',
        drifted: 12,
        unchanged: 257,
        unknown: 230
      }
    ],
    hitsPerPage: 10,
    page_number: 0,
    pagination_urls: {
      page_0: 'http://127.0.0.1:5000/api/v1/get-table?page=0'
    },
    totalCount: 4,
    totalPages: 1
  },
  'what is total hardware expense across organization?': {
    dataframe: [
      {
        'Hardware Expense': '$3,041,264'
      }
    ],
    hitsPerPage: 10,
    page_number: 0,
    pagination_urls: {
      page_0: 'http://127.0.0.1:5000/api/v1/get-table?page=0'
    },
    totalCount: 1,
    totalPages: 1
  },
  'what is cost breakdown for current microsoft license?': {
    dataframe: [
      {
        category: 'Current',
        'Annual Cost': '$1,653,715',
        'Microsoft E5': '$1,531,800',
        'Microsoft F3(Teams Online + Sec)': '$121,915'
      }
    ],
    hitsPerPage: 10,
    page_number: 0,
    pagination_urls: {
      page_0: 'http://127.0.0.1:5000/api/v1/get-table?page=0'
    },
    totalCount: 1,
    totalPages: 1
  },
  'what will be cost breakdown after optimization microsoft license?': {
    dataframe: [
      {
        category: 'Optimized',
        'Annual Cost': '$1,045,194',
        'Microsoft E5': '$741,888',
        'Microsoft F3(Teams Online + Sec)': '$68,898',
        'Microsoft F1': '$14,385',
        'Microsoft F3(Teams Desktop + Sec)': '$220,023',
        'Not active': '$0'
      }
    ],
    hitsPerPage: 10,
    page_number: 0,
    pagination_urls: {
      page_0: 'http://127.0.0.1:5000/api/v1/get-table?page=0'
    },
    totalCount: 1,
    totalPages: 1
  }
};
