import moment from 'moment';

export const routeToPageMap = {
  '/hardware': 'hardware',
  '/software': 'software',
  '/home': 'home',
  '/asset': 'asset',
  '/insight/:id': 'model-insight',
  '/datasets': 'datasets',
  '/datasets/:id': 'dataset-detail',
  '/report-tracker/insight/:id': 'user-insight',
  '/link': 'link',
  '/link/detail': 'source-detail',
  '/link/detail/:id': 'mkb-validation',
  '/tasks': 'tasks',
  '/tasks/:id': 'task-detail'
  // Add other route : pageType here
};

export const AMCHARTS_MAP_DATA = {
  query_results: {
    query_results: [
      {
        co_loc_n: 'Atlanta (HQ)',
        'Site Code': 'ATL(HQ)',
        'Site Address': '3330 Cumberland Blvd',
        mail_city_n: 'Atlanta',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30339',
        LATTD_I: 33.881064,
        LNGTD_I: -84.469953,
        variance: 1
      },
      {
        co_loc_n: 'Acworth, GA',
        'Site Code': 'ACW',
        'Site Address': '3345 Cobb Pkwy NW',
        mail_city_n: 'Acworth',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30101',
        LATTD_I: 34.049319,
        LNGTD_I: -84,
        variance: 0
      },
      {
        co_loc_n: 'Athens - Athens',
        'Site Code': 'ATN',
        'Site Address': '485 Highway 29 North',
        mail_city_n: 'Athens',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30601',
        LATTD_I: 34.002559,
        LNGTD_I: -83.377464,
        variance: 1
      },
      {
        co_loc_n: 'Buford, GA',
        'Site Code': 'BFD',
        'Site Address': '2925 Buford Drive',
        mail_city_n: 'Buford',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30519',
        LATTD_I: 34.125561,
        LNGTD_I: -84.017736,
        variance: 1
      },
      {
        co_loc_n: 'Buckhead North',
        'Site Code': 'BHP',
        'Site Address': '3617 Roswell Road NE',
        mail_city_n: 'Atlanta',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30305',
        LATTD_I: 33.857011,
        LNGTD_I: -84.372944,
        variance: 1
      },
      {
        co_loc_n: 'Columbus - Blackmon Rd',
        'Site Code': 'BKM',
        'Site Address': '7301 Blackmon Road',
        mail_city_n: 'Columbus',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '31909',
        LATTD_I: 32.529511,
        LNGTD_I: -84.921571,
        variance: 1
      },
      {
        co_loc_n: 'Athens - Barrow',
        'Site Code': 'BRW',
        'Site Address': '340 Exchange Boulevard',
        mail_city_n: 'Bethlehem',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30620',
        LATTD_I: 33.935561,
        LNGTD_I: -83.217664,
        variance: 1
      },
      {
        co_loc_n: 'Milton',
        'Site Code': 'BTB',
        'Site Address': '13081 HIGHWAY 9 N SPC',
        mail_city_n: 'Alpharetta',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30004',
        LATTD_I: 34.174561,
        LNGTD_I: -84.343336,
        variance: 1
      },
      {
        co_loc_n: 'Austell',
        'Site Code': 'BWS',
        'Site Address': '3999 Austell Road',
        mail_city_n: 'Austell',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30106',
        LATTD_I: 33.828444,
        LNGTD_I: -84.641944,
        variance: 1
      },
      {
        co_loc_n: 'Chamblee',
        'Site Code': 'CBE',
        'Site Address': '5438 Peachtree Blvd',
        mail_city_n: 'Chamblee',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30341',
        LATTD_I: 33.884561,
        LNGTD_I: -84.295556,
        variance: 1
      },
      {
        co_loc_n: 'Cartersville-Main St',
        'Site Code': 'CMA',
        'Site Address': '10 Main Street Market Place',
        mail_city_n: 'Cartersville',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30121',
        LATTD_I: 34.165667,
        LNGTD_I: -84.804444,
        variance: 1
      },
      {
        co_loc_n: 'Cumming',
        'Site Code': 'CMG',
        'Site Address': '2021 Market Pl Blvd',
        mail_city_n: 'Cumming',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30041',
        LATTD_I: 34.203722,
        LNGTD_I: -84.141944,
        variance: 1
      },
      {
        co_loc_n: 'Clairmont/Druid Hills',
        'Site Code': 'CMT',
        'Site Address': '2700 Clairmont Road',
        mail_city_n: 'Atlanta',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30329',
        LATTD_I: 33.8015,
        LNGTD_I: -84.320833,
        variance: 1
      },
      {
        co_loc_n: 'Commerce',
        'Site Code': 'COM',
        'Site Address': '110 Banks Crossing Dr',
        mail_city_n: 'Commerce',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30529',
        LATTD_I: 34.204556,
        LNGTD_I: -83.444722,
        variance: -2
      },
      {
        co_loc_n: 'Carrollton',
        'Site Code': 'CRL',
        'Site Address': '909 South Park Street',
        mail_city_n: 'Carrolton',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30117',
        LATTD_I: 33.578444,
        LNGTD_I: -85.078611,
        variance: 1
      },
      {
        co_loc_n: 'Canton(GA)',
        'Site Code': 'CTN-GA',
        'Site Address': '2026 Cumming Hwy',
        mail_city_n: 'Canton',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30115',
        LATTD_I: 34.235944,
        LNGTD_I: -84.479444,
        variance: 1
      },
      {
        co_loc_n: 'Cumming Tribble Road',
        'Site Code': 'CTR',
        'Site Address': '3420 Canton Hwy',
        mail_city_n: 'Cumming',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30040',
        LATTD_I: 34.215944,
        LNGTD_I: -84.195833,
        variance: 1
      },
      {
        co_loc_n: 'Covington',
        'Site Code': 'CVT',
        'Site Address': '5239 Highway 278 NE',
        mail_city_n: 'Covington',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30014',
        LATTD_I: 33.6265,
        LNGTD_I: -83.855556,
        variance: 1
      },
      {
        co_loc_n: 'Cartersville-West Ave',
        'Site Code': 'CWA',
        'Site Address': '11 Charley Harper Drive',
        mail_city_n: 'Cartersville',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30120',
        LATTD_I: 34.175667,
        LNGTD_I: -84.812778,
        variance: 0
      },
      {
        co_loc_n: 'Conyers',
        'Site Code': 'CYR',
        'Site Address': '1280 Dogwood Dr SE',
        mail_city_n: 'Conyers',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30013',
        LATTD_I: 33.662556,
        LNGTD_I: -84.005833,
        variance: 1
      },
      {
        co_loc_n: 'Decatur',
        'Site Code': 'DCR',
        'Site Address': '2520 N Decatur Rd',
        mail_city_n: 'Decatur',
        MAIL_ST_PROV_C: 'Ga',
        'Zip Code': '30033',
        LATTD_I: 33.774556,
        LNGTD_I: -84.288611,
        variance: 1
      },
      {
        co_loc_n: 'Dallas',
        'Site Code': 'DLS',
        'Site Address': '500 Nathan Dean Blvd',
        mail_city_n: 'Dallas',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30157',
        LATTD_I: 33.915944,
        LNGTD_I: -84.842222,
        variance: -1
      },
      {
        co_loc_n: 'Douglasville',
        'Site Code': 'DVE',
        'Site Address': '2976 Chapel Hill Rd',
        mail_city_n: 'Douglasville',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30135',
        LATTD_I: 33.744556,
        LNGTD_I: -84.751667,
        variance: 1
      },
      {
        co_loc_n: 'East Point',
        'Site Code': 'EPT',
        'Site Address': '3515 Camp Creek Parkway',
        mail_city_n: 'East Point',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30344',
        LATTD_I: 33.655944,
        LNGTD_I: -84.467222,
        variance: 1
      },
      {
        co_loc_n: 'Ellenwood',
        'Site Code': 'EWD',
        'Site Address': '4475 W Village Pkwy',
        mail_city_n: 'Ellenwood',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30294',
        LATTD_I: 33.609556,
        LNGTD_I: -84.321944,
        variance: 1
      },
      {
        co_loc_n: 'Fairburn',
        'Site Code': 'FBN',
        'Site Address': '8028 Senoia Rd',
        mail_city_n: 'Fairburn',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30213',
        LATTD_I: 33.560944,
        LNGTD_I: -84.671667,
        variance: 1
      },
      {
        co_loc_n: 'Fayetteville North',
        'Site Code': 'FVN',
        'Site Address': '1175 N. Glynn St, Suite 140',
        mail_city_n: 'Fayetteville',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30214',
        LATTD_I: 33.462556,
        LNGTD_I: -84.463056,
        variance: 1
      },
      {
        co_loc_n: 'Fayetteville',
        'Site Code': 'FYT',
        'Site Address': '749 W Lanier Ave',
        mail_city_n: 'Fayetteville',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30214',
        LATTD_I: 33.446556,
        LNGTD_I: -84.464444,
        variance: 1
      },
      {
        co_loc_n: 'Grayson',
        'Site Code': 'GRY',
        'Site Address': '2594 Loganville Hwy',
        mail_city_n: 'Grayson',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30017',
        LATTD_I: 33.895944,
        LNGTD_I: -83.962778,
        variance: 1
      },
      {
        co_loc_n: 'Hapeville',
        'Site Code': 'HAP',
        'Site Address': '1001 Virginia Ave',
        mail_city_n: 'Hapeville',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30354',
        LATTD_I: 33.660944,
        LNGTD_I: -84.420278,
        variance: 1
      },
      {
        co_loc_n: 'Holcomb Bridge/East Roswell',
        'Site Code': 'HBR',
        'Site Address': '2701 Holcomb Bridge Road',
        mail_city_n: 'Alpharetta',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30022',
        LATTD_I: 34.034556,
        LNGTD_I: -84.266944,
        variance: 1
      },
      {
        co_loc_n: 'Hickory Flat',
        'Site Code': 'HFT',
        'Site Address': '7820 Hickory Flat Hwy',
        mail_city_n: 'Woodstock',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30188',
        LATTD_I: 34.164556,
        LNGTD_I: -84.565833,
        variance: 1
      },
      {
        co_loc_n: 'South Forsyth',
        'Site Code': 'JCK',
        'Site Address': '2637 Peachtree Pkwy',
        mail_city_n: 'Suwanee',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30024',
        LATTD_I: 34.064556,
        LNGTD_I: -84.153611,
        variance: 1
      },
      {
        co_loc_n: 'Athens - Jefferson',
        'Site Code': 'JFN',
        'Site Address': '528 Panther Drive',
        mail_city_n: 'Jefferson',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30549',
        LATTD_I: 34.135944,
        LNGTD_I: -83.372222,
        variance: 1
      },
      {
        co_loc_n: 'Kennesaw - Bells Ferry',
        'Site Code': 'KBF',
        'Site Address': '4282 Bells Ferry Road NW',
        mail_city_n: 'Kennesaw',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30144',
        LATTD_I: 34.050944,
        LNGTD_I: -84.606944,
        variance: 1
      },
      {
        co_loc_n: 'Marietta',
        'Site Code': 'KNE',
        'Site Address': '789 Church St',
        mail_city_n: 'Marietta',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30060',
        LATTD_I: 33.954556,
        LNGTD_I: -84.542222,
        variance: 1
      },
      {
        co_loc_n: 'Lawrenceville',
        'Site Code': 'LAW',
        'Site Address': '3330 Sugarloaf Pkwy',
        mail_city_n: 'Lawrenceville',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30044',
        LATTD_I: 33.952556,
        LNGTD_I: -83.991944,
        variance: 1
      },
      {
        co_loc_n: 'Loganville',
        'Site Code': 'LGN',
        'Site Address': '4763 Atlanta Highway',
        mail_city_n: 'Loganville',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '',
        LATTD_I: 33.834556,
        LNGTD_I: -83.893889,
        variance: 1
      },
      {
        co_loc_n: 'Lagrange',
        'Site Code': 'LGR',
        'Site Address': '1524 Lafayette Parkway',
        mail_city_n: 'Lagrange',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30241',
        LATTD_I: 33.035944,
        LNGTD_I: -85.034444,
        variance: 1
      },
      {
        co_loc_n: 'Loganville-Hwy 81',
        'Site Code': 'LHW',
        'Site Address': '3435 Hwy 81',
        mail_city_n: 'Loganville',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30052',
        LATTD_I: 33.834556,
        LNGTD_I: -83.901944,
        variance: 1
      },
      {
        co_loc_n: 'Lilburn',
        'Site Code': 'LIL',
        'Site Address': '976 Killian Hill rd., SW',
        mail_city_n: 'Lilburn',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30047',
        LATTD_I: 33.882556,
        LNGTD_I: -84.145278,
        variance: 1
      },
      {
        co_loc_n: 'Lithonia',
        'Site Code': 'LIT',
        'Site Address': '7231 Rockbridge Rd',
        mail_city_n: 'Lithonia',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30058',
        LATTD_I: 33.721556,
        LNGTD_I: -84.105556,
        variance: 1
      },
      {
        co_loc_n: 'Lawrenceville - Old Peachtree',
        'Site Code': 'LOP',
        'Site Address': '1034 Old Peachtree Rd NW',
        mail_city_n: 'Lawrenceville',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30043',
        LATTD_I: 33.982556,
        LNGTD_I: -84.026944,
        variance: 1
      },
      {
        co_loc_n: 'McDonough',
        'Site Code': 'MCD',
        'Site Address': '101 Regency Park Drive',
        mail_city_n: 'McDonough',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30253',
        LATTD_I: 33.444556,
        LNGTD_I: -84.163611,
        variance: 1
      },
      {
        co_loc_n: 'Macon Ingleside',
        'Site Code': 'MCI',
        'Site Address': '2410 Ingleside Ave',
        mail_city_n: 'Macon',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '31204',
        LATTD_I: 32.875944,
        LNGTD_I: -83.693889,
        variance: -2
      },
      {
        co_loc_n: 'McDonough - Jonesboro Rd',
        'Site Code': 'MJR',
        'Site Address': '2050 Jonesboro Rd',
        mail_city_n: 'McDonough',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30253',
        LATTD_I: 33.444556,
        LNGTD_I: -84.163611,
        variance: 1
      },
      {
        co_loc_n: 'Macon Occupational Medicine',
        'Site Code': 'MOM',
        'Site Address': '124 3rd St',
        mail_city_n: 'Macon',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '31201',
        LATTD_I: 32.840944,
        LNGTD_I: -83.633611,
        variance: 1
      },
      {
        co_loc_n: 'East Cobb',
        'Site Code': 'MRT',
        'Site Address': '3120 Johnson Ferry Rd',
        mail_city_n: 'Marietta',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30062',
        LATTD_I: 33.925944,
        LNGTD_I: -84.416944,
        variance: 1
      },
      {
        co_loc_n: 'Dunwoody',
        'Site Code': 'MTV',
        'Site Address': '1575 Mount Vernon Road',
        mail_city_n: 'Dunwoody',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30338',
        LATTD_I: 33.915944,
        LNGTD_I: -84.333056,
        variance: 1
      },
      {
        co_loc_n: 'Macon, GA (Zebulon Road)',
        'Site Code': 'MZR',
        'Site Address': '5791 Zebulon Ste A',
        mail_city_n: 'Macon',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '31210',
        LATTD_I: 32.895944,
        LNGTD_I: -83.703889,
        variance: 0
      },
      {
        co_loc_n: 'Newnan Crossing Blvd',
        'Site Code': 'NCB',
        'Site Address': '2084 Newnan Crossing Blvd',
        mail_city_n: 'Newnan',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30265',
        LATTD_I: 33.382556,
        LNGTD_I: -84.763056,
        variance: 1
      },
      {
        co_loc_n: 'Norcross',
        'Site Code': 'NOR',
        'Site Address': '6063 Peachtree Parkway',
        mail_city_n: 'Peachtree Corners',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30092',
        LATTD_I: 33.955944,
        LNGTD_I: -84.215278,
        variance: 1
      },
      {
        co_loc_n: 'Newnan',
        'Site Code': 'NWN',
        'Site Address': '1825 GA-34',
        mail_city_n: 'Newnan',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30265',
        LATTD_I: 33.355944,
        LNGTD_I: -84.793056,
        variance: 1
      },
      {
        co_loc_n: 'Athens - Oconee Health',
        'Site Code': 'OCN',
        'Site Address': '1305 Jennings Mill Rd',
        mail_city_n: 'Watkinsville',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30677',
        LATTD_I: 33.865944,
        LNGTD_I: -83.403056,
        variance: 1
      },
      {
        co_loc_n: 'Ponce De Leon',
        'Site Code': 'PDL',
        'Site Address': '882 Ponce De Leon Avenue',
        mail_city_n: 'Atlanta',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30306',
        LATTD_I: 33.771556,
        LNGTD_I: -84.355833
      },
      {
        co_loc_n: 'Duluth, GA Promenade at Pleasant Hill',
        'Site Code': 'PHL',
        'Site Address': '1605 Pleasant Hill Rd',
        mail_city_n: 'Duluth',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30096',
        LATTD_I: 33.995944,
        LNGTD_I: -84.133056
      },
      {
        co_loc_n: 'Peachtree mail_city_n',
        'Site Code': 'PTC',
        'Site Address': '100 Line Creek Drive',
        mail_city_n: 'Peachtree mail_city_n',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30269',
        LATTD_I: 33.382556,
        LNGTD_I: -84.573056
      },
      {
        co_loc_n: 'Columbus - Peachtree Mall',
        'Site Code': 'PTM',
        'Site Address': '3471 Courtyard Way',
        mail_city_n: 'Columbus',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '31909',
        LATTD_I: 32.529511,
        LNGTD_I: -84.921571
      },
      {
        co_loc_n: 'Buckhead South',
        'Site Code': 'PTS',
        'Site Address': '2292 Peachtree Road, NW',
        mail_city_n: 'Atlanta',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30309',
        LATTD_I: 33.841556,
        LNGTD_I: -84.373056
      },
      {
        co_loc_n: 'Roswell - Crabapple',
        'Site Code': 'RCR',
        'Site Address': '10971 Crabapple Road',
        mail_city_n: 'Roswell',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30075',
        LATTD_I: 34.045944,
        LNGTD_I: -84.343056
      },
      {
        co_loc_n: 'East Rome',
        'Site Code': 'REA',
        'Site Address': '1810 Turner McCall Blvd.',
        mail_city_n: 'Rome',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30161',
        LATTD_I: 34.255944,
        LNGTD_I: -85.153056
      },
      {
        co_loc_n: 'West Rome',
        'Site Code': 'RWE',
        'Site Address': '609 Shorter Avenue SW',
        mail_city_n: 'Rome',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30165',
        LATTD_I: 34.255944,
        LNGTD_I: -85.183056
      },
      {
        co_loc_n: 'Snellville-Centerville Hwy',
        'Site Code': 'SCH',
        'Site Address': '3641 Centerville Hwy',
        mail_city_n: 'Snellville',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30039',
        LATTD_I: 33.855944,
        LNGTD_I: -84.033056
      },
      {
        co_loc_n: 'Johns Creek/Alpharetta',
        'Site Code': 'SDB',
        'Site Address': '10945 MAIL_ST_PROV_C Bridge Road',
        mail_city_n: 'Alpharetta',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30022',
        LATTD_I: 34.034556,
        LNGTD_I: -84.266944
      },
      {
        co_loc_n: 'Summerhill',
        'Site Code': 'SMH',
        'Site Address': '572 Hank Aaron Drive',
        mail_city_n: 'Atlanta',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30312',
        LATTD_I: 33.735944,
        LNGTD_I: -84.383056
      },
      {
        co_loc_n: 'Smyrna',
        'Site Code': 'SMY',
        'Site Address': '1155 Concord Rd SE',
        mail_city_n: 'Smyrna',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30080',
        LATTD_I: 33.882556,
        LNGTD_I: -84.513056
      },
      {
        co_loc_n: 'Senoia',
        'Site Code': 'SNA',
        'Site Address': '7990 Wells Street',
        mail_city_n: 'Senoia',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30276',
        LATTD_I: 33.315944,
        LNGTD_I: -84.543056
      },
      {
        co_loc_n: 'Snellville',
        'Site Code': 'SNV',
        'Site Address': '2118 Scenic Highway South ',
        mail_city_n: 'Snellville',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '',
        LATTD_I: 33.855944,
        LNGTD_I: -84.033056
      },
      {
        co_loc_n: 'Sandy Springs Village',
        'Site Code': 'SSV',
        'Site Address': '6660 Roswell Road NE',
        mail_city_n: 'Sandy Springs',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30328',
        LATTD_I: 33.955944,
        LNGTD_I: -84.343056
      },
      {
        co_loc_n: 'Stockbridge',
        'Site Code': 'STK',
        'Site Address': '921 Eagles Landing Parkway',
        mail_city_n: 'Stockbridge',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '',
        LATTD_I: 33.544556,
        LNGTD_I: -84.233056
      },
      {
        co_loc_n: 'Tucker',
        'Site Code': 'TKR',
        'Site Address': '4650 Hugh Howell Rd',
        mail_city_n: 'Tucker',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30084',
        LATTD_I: 33.842556,
        LNGTD_I: -84.223056
      },
      {
        co_loc_n: 'Columbus - Uptown',
        'Site Code': 'UTN',
        'Site Address': '101 13th street',
        mail_city_n: 'Columbus',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '31901',
        LATTD_I: 32.465944,
        LNGTD_I: -84.983056
      },
      {
        co_loc_n: 'West Cobb',
        'Site Code': 'WCB',
        'Site Address': '2505 Dallas Hwy',
        mail_city_n: 'Marietta',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30064',
        LATTD_I: 33.925944,
        LNGTD_I: -84.606944
      },
      {
        co_loc_n: 'Athens - Watkinsville',
        'Site Code': 'WKV',
        'Site Address': '1010 Village Drive',
        mail_city_n: 'Watkinsville',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30677',
        LATTD_I: 33.865944,
        LNGTD_I: -83.403056
      },
      {
        co_loc_n: 'Woodstock',
        'Site Code': 'WSK',
        'Site Address': '285 Ridgewalk Parkway',
        mail_city_n: 'Woodstock',
        MAIL_ST_PROV_C: 'GA',
        'Zip Code': '30188',
        LATTD_I: 34.105944,
        LNGTD_I: -84.533056
      },
      {
        co_loc_n: 'Allen Park',
        'Site Code': 'ALP',
        'Site Address': '15606 Southfield Road',
        mail_city_n: 'Allen Park',
        MAIL_ST_PROV_C: 'MI',
        'Zip Code': '48101',
        LATTD_I: 42.255944,
        LNGTD_I: -83.213056
      },
      {
        co_loc_n: 'Bloomfield Hills',
        'Site Code': 'BFH',
        'Site Address': '42757 Woodward Ave',
        mail_city_n: 'Bloomfield Hills',
        MAIL_ST_PROV_C: 'MI',
        'Zip Code': '48304',
        LATTD_I: 42.585944,
        LNGTD_I: -83.263056
      },
      {
        co_loc_n: 'Bloomfield Township',
        'Site Code': 'BFT',
        'Site Address': '6543 Telegraph Road',
        mail_city_n: 'Bloomfield Township',
        MAIL_ST_PROV_C: 'MI',
        'Zip Code': '48301',
        LATTD_I: 42.565944,
        LNGTD_I: -83.283056
      },
      {
        co_loc_n: 'Chesterfield',
        'Site Code': 'CTF',
        'Site Address': '50964 Gratiot Ave',
        mail_city_n: 'Chesterfield Township',
        MAIL_ST_PROV_C: 'MI',
        'Zip Code': '48051',
        LATTD_I: 42.645944,
        LNGTD_I: -82.813056
      },
      {
        co_loc_n: 'Canton(MI)',
        'Site Code': 'CTN-MI',
        'Site Address': '6549 N. Canton Center Road',
        mail_city_n: 'Canton',
        MAIL_ST_PROV_C: 'MI',
        'Zip Code': '48187',
        LATTD_I: 42.345944,
        LNGTD_I: -83.473056
      },
      {
        co_loc_n: 'Dearborn',
        'Site Code': 'DBN',
        'Site Address': '23100 Michigan Ave',
        mail_city_n: 'Dearborn',
        MAIL_ST_PROV_C: 'MI',
        'Zip Code': '48124',
        LATTD_I: 42.325944,
        LNGTD_I: -83.243056
      },
      {
        co_loc_n: 'Downtown Royal Oak',
        'Site Code': 'DRO',
        'Site Address': '309 E 11 Mile Rd',
        mail_city_n: 'Royal Oak',
        MAIL_ST_PROV_C: 'MI',
        'Zip Code': '48067',
        LATTD_I: 42.495944,
        LNGTD_I: -83.153056
      },
      {
        co_loc_n: 'Farmington Hills North',
        'Site Code': 'FHN',
        'Site Address': '29263 Orchard Lake Rd',
        mail_city_n: 'Farmington Hills',
        MAIL_ST_PROV_C: 'MI',
        'Zip Code': '48334',
        LATTD_I: 42.515944,
        LNGTD_I: -83.373056
      },
      {
        co_loc_n: 'Farmington Hills South',
        'Site Code': 'FTH',
        'Site Address': '27810 Grand River Avenue',
        mail_city_n: 'Farmington Hills',
        MAIL_ST_PROV_C: 'MI',
        'Zip Code': '48336',
        LATTD_I: 42.485944,
        LNGTD_I: -83.373056
      },
      {
        co_loc_n: 'Garden mail_city_n',
        'Site Code': 'GDC',
        'Site Address': '6200 Middlebelt Road',
        mail_city_n: 'Garden mail_city_n',
        MAIL_ST_PROV_C: 'MI',
        'Zip Code': '48135',
        LATTD_I: 42.315944,
        LNGTD_I: -83.333056
      },
      {
        co_loc_n: 'Grosse Pointe Farms',
        'Site Code': 'GPF',
        'Site Address': '18700 Mack Ave',
        mail_city_n: 'Grosse Pointe Farms',
        MAIL_ST_PROV_C: 'MI',
        'Zip Code': '',
        LATTD_I: 42.4092038,
        LNGTD_I: -82.8918587
      },
      {
        co_loc_n: 'Haggerty Square',
        'Site Code': 'HTS',
        'Site Address': '19760 Haggerty Rd',
        mail_city_n: 'Livonia',
        MAIL_ST_PROV_C: 'MI',
        'Zip Code': '48152',
        LATTD_I: 42.415944,
        LNGTD_I: -83.413056
      },
      {
        co_loc_n: 'Lake Orion',
        'Site Code': 'LKO',
        'Site Address': '845 S. Lapeer Rd.',
        mail_city_n: 'Orion Charter Township',
        MAIL_ST_PROV_C: 'MI',
        'Zip Code': '48362',
        LATTD_I: 42.775944,
        LNGTD_I: -83.253056
      },
      {
        co_loc_n: 'Macomb',
        'Site Code': 'MCB',
        'Site Address': '15959 Hall Road',
        mail_city_n: 'Macomb',
        MAIL_ST_PROV_C: 'MI',
        'Zip Code': '48044',
        LATTD_I: 42.665944,
        LNGTD_I: -82.933056
      },
      {
        co_loc_n: 'Novi',
        'Site Code': 'NVI',
        'Site Address': '43535 Grand River Ave',
        mail_city_n: 'Novi',
        MAIL_ST_PROV_C: 'MI',
        'Zip Code': '48375',
        LATTD_I: 42.465944,
        LNGTD_I: -83.473056
      },
      {
        co_loc_n: 'Rochester Hills',
        'Site Code': 'RHS',
        'Site Address': '3200 Walton Boulevard',
        mail_city_n: 'Rochester Hills',
        MAIL_ST_PROV_C: 'MI',
        'Zip Code': '48309',
        LATTD_I: 42.655944,
        LNGTD_I: -83.153056
      },
      {
        co_loc_n: 'Shelby Township',
        'Site Code': 'SBY',
        'Site Address': '56293 Van Dyke Ave',
        mail_city_n: 'Shelby Township',
        MAIL_ST_PROV_C: 'MI',
        'Zip Code': '48316',
        LATTD_I: 42.675944,
        LNGTD_I: -83.033056
      },
      {
        co_loc_n: 'St. Clair Shores',
        'Site Code': 'SCS',
        'Site Address': '25631 Little Mack Ave',
        mail_city_n: 'St Clair Shores',
        MAIL_ST_PROV_C: 'MI',
        'Zip Code': '48081',
        LATTD_I: 42.495944,
        LNGTD_I: -82.893056
      },
      {
        co_loc_n: 'Southfield HQ',
        'Site Code': 'SFT',
        'Site Address': '26901 Beaumont Blvd',
        mail_city_n: 'Southfield',
        MAIL_ST_PROV_C: 'MI',
        'Zip Code': '48033',
        LATTD_I: 42.475944,
        LNGTD_I: -83.243056
      },
      {
        co_loc_n: 'Southgate',
        'Site Code': 'SGT',
        'Site Address': '16525 Fort Street',
        mail_city_n: 'Southgate',
        MAIL_ST_PROV_C: 'MI',
        'Zip Code': '48195',
        LATTD_I: 42.195944,
        LNGTD_I: -83.213056
      },
      {
        co_loc_n: 'Troy Big Beaver',
        'Site Code': 'TBB',
        'Site Address': '2078 E. Big Beaver Road',
        mail_city_n: 'Troy',
        MAIL_ST_PROV_C: 'MI',
        'Zip Code': '48083',
        LATTD_I: 42.595944,
        LNGTD_I: -83.153056
      },
      {
        co_loc_n: 'Taylor',
        'Site Code': 'TLR',
        'Site Address': '9870 Telegraph Road',
        mail_city_n: 'Taylor',
        MAIL_ST_PROV_C: 'MI',
        'Zip Code': '48180',
        LATTD_I: 42.235944,
        LNGTD_I: -83.273056
      },
      {
        co_loc_n: 'Troy Square Lake',
        'Site Code': 'TSL',
        'Site Address': '43037 Dequindre Road',
        mail_city_n: 'Troy',
        MAIL_ST_PROV_C: 'MI',
        'Zip Code': '48085',
        LATTD_I: 42.595944,
        LNGTD_I: -83.173056
      },
      {
        co_loc_n: 'West Bloomfield',
        'Site Code': 'WBF',
        'Site Address': '6470 Orchard Lake Road',
        mail_city_n: 'West Bloomfield',
        MAIL_ST_PROV_C: 'MI',
        'Zip Code': '48322',
        LATTD_I: 42.565944,
        LNGTD_I: -83.393056
      },
      {
        co_loc_n: 'Woodhaven',
        'Site Code': 'WHN',
        'Site Address': '18930 West Road',
        mail_city_n: 'Woodhaven',
        MAIL_ST_PROV_C: 'MI',
        'Zip Code': '48183',
        LATTD_I: 42.155944,
        LNGTD_I: -83.243056
      },
      {
        co_loc_n: 'Warren East',
        'Site Code': 'WRE',
        'Site Address': '28550 Schoenherr Rd',
        mail_city_n: 'Warren',
        MAIL_ST_PROV_C: 'MI',
        'Zip Code': '48088',
        LATTD_I: 42.515944,
        LNGTD_I: -83.003056
      },
      {
        co_loc_n: 'Warren West',
        'Site Code': 'WRW',
        'Site Address': '28284 Dequindre Rd',
        mail_city_n: 'Warren',
        MAIL_ST_PROV_C: 'MI',
        'Zip Code': '48092',
        LATTD_I: 42.515944,
        LNGTD_I: -83.033056
      },
      {
        co_loc_n: 'Woodward Corners',
        'Site Code': 'WWC',
        'Site Address': '30955 Woodward Avenue',
        mail_city_n: 'Royal Oak',
        MAIL_ST_PROV_C: 'MI',
        'Zip Code': '48073',
        LATTD_I: 42.495944,
        LNGTD_I: -83.153056
      },
      {
        co_loc_n: 'Wayne',
        'Site Code': 'WYE',
        'Site Address': '34435 Michigan Avenue',
        mail_city_n: 'Wayne',
        MAIL_ST_PROV_C: 'MI',
        'Zip Code': '48184',
        LATTD_I: 42.265944,
        LNGTD_I: -83.393056
      },
      {
        co_loc_n: 'Ashtabula',
        'Site Code': 'ASH',
        'Site Address': '3315 North Ridge Road E',
        mail_city_n: 'Ashtabula',
        MAIL_ST_PROV_C: 'OH',
        'Zip Code': '44004',
        LATTD_I: 41.885944,
        LNGTD_I: -80.773056
      },
      {
        co_loc_n: 'Avon',
        'Site Code': 'AVN',
        'Site Address': '1997 Healthway Dr',
        mail_city_n: 'Avon',
        MAIL_ST_PROV_C: 'OH',
        'Zip Code': '44011',
        LATTD_I: 41.455944,
        LNGTD_I: -82.023056
      },
      {
        co_loc_n: 'Beachwood',
        'Site Code': 'BEE',
        'Site Address': '2101 Richmond Rd',
        mail_city_n: 'Beechwood',
        MAIL_ST_PROV_C: 'OH',
        'Zip Code': '44122',
        LATTD_I: 41.495944,
        LNGTD_I: -81.513056
      },
      {
        co_loc_n: 'Broadview Heights',
        'Site Code': 'BHT',
        'Site Address': '5901 East Royalton Rd',
        mail_city_n: 'Broadview Heights',
        MAIL_ST_PROV_C: 'OH',
        'Zip Code': '44147',
        LATTD_I: 41.335944,
        LNGTD_I: -81.673056
      },
      {
        co_loc_n: 'Kent',
        'Site Code': 'KNT',
        'Site Address': '1005 E Main St',
        mail_city_n: 'Kent',
        MAIL_ST_PROV_C: 'OH',
        'Zip Code': '44240',
        LATTD_I: 41.155944,
        LNGTD_I: -81.353056
      },
      {
        co_loc_n: 'Lakewood West',
        'Site Code': 'LKW',
        'Site Address': '14800 Madison Avenue',
        mail_city_n: 'Lakewood',
        MAIL_ST_PROV_C: 'OH',
        'Zip Code': '44107',
        LATTD_I: 41.485944,
        LNGTD_I: -81.813056
      },
      {
        co_loc_n: 'Madison',
        'Site Code': 'MAD',
        'Site Address': '6270 N Ridge Rd',
        mail_city_n: 'Madison',
        MAIL_ST_PROV_C: 'OH',
        'Zip Code': '44057',
        LATTD_I: 41.825944,
        LNGTD_I: -81.043056
      },
      {
        co_loc_n: 'Mayfield Heights',
        'Site Code': 'MAY',
        'Site Address': '1300 Som Center Rd',
        mail_city_n: 'Mayfield Heights',
        MAIL_ST_PROV_C: 'OH',
        'Zip Code': '44124',
        LATTD_I: 41.525944,
        LNGTD_I: -81.463056
      },
      {
        co_loc_n: 'Medina',
        'Site Code': 'MED',
        'Site Address': '4001 Carrick Dr.',
        mail_city_n: 'Medina',
        MAIL_ST_PROV_C: 'OH',
        'Zip Code': '44077',
        LATTD_I: 41.135944,
        LNGTD_I: -81.863056
      },
      {
        co_loc_n: 'Mentor',
        'Site Code': 'MNT',
        'Site Address': '8655 Market St',
        mail_city_n: 'Mentor',
        MAIL_ST_PROV_C: 'OH',
        'Zip Code': '44060',
        LATTD_I: 41.675944,
        LNGTD_I: -81.333056
      },
      {
        co_loc_n: 'Concord',
        'Site Code': 'PAN',
        'Site Address': '7500 Auburn Rd.',
        mail_city_n: 'Concord Township',
        MAIL_ST_PROV_C: 'OH',
        'Zip Code': '44256',
        LATTD_I: 41.645944,
        LNGTD_I: -81.283056
      },
      {
        co_loc_n: 'Minoff (Chagrin Highlands)',
        'Site Code': 'PIN',
        'Site Address': '3909 Orange Place',
        mail_city_n: 'Beachwood',
        MAIL_ST_PROV_C: 'OH',
        'Zip Code': '44122',
        LATTD_I: 41.495944,
        LNGTD_I: -81.483056
      },
      {
        co_loc_n: 'Shaker Heights',
        'Site Code': 'SHH',
        'Site Address': '16601 Chagrin Blvd',
        mail_city_n: 'Shaker Heights',
        MAIL_ST_PROV_C: 'OH',
        'Zip Code': '44120',
        LATTD_I: 41.485944,
        LNGTD_I: -81.543056
      },
      {
        co_loc_n: 'Solon',
        'Site Code': 'SLN',
        'Site Address': '6140 SOM Center Rd',
        mail_city_n: 'Solon',
        MAIL_ST_PROV_C: 'OH',
        'Zip Code': '44139',
        LATTD_I: 41.385944,
        LNGTD_I: -81.443056
      },
      {
        co_loc_n: 'Streetsboro',
        'Site Code': 'STR',
        'Site Address': '9318 MAIL_ST_PROV_C Route 14',
        mail_city_n: 'Streetsboro',
        MAIL_ST_PROV_C: 'OH',
        'Zip Code': '44241',
        LATTD_I: 41.235944,
        LNGTD_I: -81.353056
      },
      {
        co_loc_n: 'Twinsburg',
        'Site Code': 'TWN',
        'Site Address': '8819 Commons Blvd',
        mail_city_n: 'Twinsburg',
        MAIL_ST_PROV_C: 'OH',
        'Zip Code': '44087',
        LATTD_I: 41.315944,
        LNGTD_I: -81.443056
      },
      {
        co_loc_n: 'Willowick',
        'Site Code': 'WIL',
        'Site Address': '29804 Lakeshore Blvd',
        mail_city_n: 'Willowick',
        MAIL_ST_PROV_C: 'OH',
        'Zip Code': '44095',
        LATTD_I: 41.645944,
        LNGTD_I: -81.463056
      },
      {
        co_loc_n: 'Willoughby',
        'Site Code': 'WLB',
        'Site Address': '36001 Euclid Ave',
        mail_city_n: 'Willoughby',
        MAIL_ST_PROV_C: 'OH',
        'Zip Code': '44094',
        LATTD_I: 41.645944,
        LNGTD_I: -81.433056
      },
      {
        co_loc_n: 'Westlake',
        'Site Code': 'WLW',
        'Site Address': '960 Clague Rd',
        mail_city_n: 'Westlake',
        MAIL_ST_PROV_C: 'OH',
        'Zip Code': '44145',
        LATTD_I: 41.455944,
        LNGTD_I: -81.923056
      },
      {
        co_loc_n: 'Anderson',
        'Site Code': 'AND',
        'Site Address': '3322 N Main Street',
        mail_city_n: 'Anderson',
        MAIL_ST_PROV_C: 'SC',
        'Zip Code': '29621',
        LATTD_I: 34.515944,
        LNGTD_I: -82.653056
      },
      {
        co_loc_n: 'Columbia - Forest Drive',
        'Site Code': 'CFD',
        'Site Address': '2220 N Beltline Blvd',
        mail_city_n: 'Columbia',
        MAIL_ST_PROV_C: 'SC',
        'Zip Code': '29204',
        LATTD_I: 34.035944,
        LNGTD_I: -81.053056
      },
      {
        co_loc_n: 'Columbia - Five Points',
        'Site Code': 'CFP',
        'Site Address': '944 Harden St',
        mail_city_n: 'Columbia',
        MAIL_ST_PROV_C: 'SC',
        'Zip Code': '29205',
        LATTD_I: 34.015944,
        LNGTD_I: -81.033056
      },
      {
        co_loc_n: "Columbia - Garner's Ferry",
        'Site Code': 'CGF',
        'Site Address': '7613 Garners Ferry Rd',
        mail_city_n: 'Columbia',
        MAIL_ST_PROV_C: 'SC',
        'Zip Code': '29209',
        LATTD_I: 33.985944,
        LNGTD_I: -80.983056
      },
      {
        co_loc_n: 'Columbia - Harbison Blvd',
        'Site Code': 'CHB',
        'Site Address': '131 Harbison Blvd',
        mail_city_n: 'Columbia',
        MAIL_ST_PROV_C: 'SC',
        'Zip Code': '29212',
        LATTD_I: 34.035944,
        LNGTD_I: -81.153056
      },
      {
        co_loc_n: 'ParkRidge',
        'Site Code': 'CPR',
        'Site Address': '100 Palmetto Health Pkwy',
        mail_city_n: 'Columbia',
        MAIL_ST_PROV_C: 'SC',
        'Zip Code': '29212',
        LATTD_I: 34.035944,
        LNGTD_I: -81.153056
      },
      {
        co_loc_n: 'Duncan',
        'Site Code': 'DUN',
        'Site Address': '1515 E Main Street',
        mail_city_n: 'Duncan',
        MAIL_ST_PROV_C: 'SC',
        'Zip Code': '29334',
        LATTD_I: 34.935944,
        LNGTD_I: -82.153056
      },
      {
        co_loc_n: 'Greer',
        'Site Code': 'GRE',
        'Site Address': '1305 S Suber Rd.',
        mail_city_n: 'Greer',
        MAIL_ST_PROV_C: 'SC',
        'Zip Code': '29650',
        LATTD_I: 34.935944,
        LNGTD_I: -82.223056
      },
      {
        co_loc_n: 'Verdae (Greenville)',
        'Site Code': 'GVV',
        'Site Address': '905 Verdae Blvd',
        mail_city_n: 'Greenville',
        MAIL_ST_PROV_C: 'SC',
        'Zip Code': '29607',
        LATTD_I: 34.865944,
        LNGTD_I: -82.313056
      },
      {
        co_loc_n: 'Greenville - Woodruff',
        'Site Code': 'GWR',
        'Site Address': '1298 Woodruff Rd',
        mail_city_n: 'Greenville',
        MAIL_ST_PROV_C: 'SC',
        'Zip Code': '29607',
        LATTD_I: 34.865944,
        LNGTD_I: -82.333056
      },
      {
        co_loc_n: 'Powdersville',
        'Site Code': 'PWD',
        'Site Address': '11402 Anderson Rd',
        mail_city_n: 'Greenville',
        MAIL_ST_PROV_C: 'SC',
        'Zip Code': '29611',
        LATTD_I: 34.795944,
        LNGTD_I: -82.483056
      },
      {
        co_loc_n: 'Simpsonville (Scuffletown)',
        'Site Code': 'SIM',
        'Site Address': '300 Scuffletown Rd.',
        mail_city_n: 'Simpsonville',
        MAIL_ST_PROV_C: 'SC',
        'Zip Code': '29681',
        LATTD_I: 34.735944,
        LNGTD_I: -82.253056
      },
      {
        co_loc_n: 'Spartanburg',
        'Site Code': 'SPT',
        'Site Address': '1501 WO Ezell Blvd',
        mail_city_n: 'Spartanburg',
        MAIL_ST_PROV_C: 'SC',
        'Zip Code': '29301',
        LATTD_I: 34.965944,
        LNGTD_I: -81.923056
      },
      {
        co_loc_n: 'Taylors',
        'Site Code': 'TAY',
        'Site Address': '3025 Wade Hampton Blvd',
        mail_city_n: 'Taylors',
        MAIL_ST_PROV_C: 'SC',
        'Zip Code': '29687',
        LATTD_I: 34.915944,
        LNGTD_I: -82.313056
      }
    ]
  }
};

export const INSIGHT_STATES = [
  {
    label: 'Snoozed',
    value: 'snoozed',
    color: 'primary'
  },
  {
    label: 'Assigned',
    value: 'assigned',
    color: 'primary'
  },
  {
    label: 'WIP',
    value: 'work in progress',
    color: 'success'
  },
  {
    label: 'Reassigned',
    value: 'reassigned',
    color: 'primary'
  },
  {
    label: 'On Hold',
    value: 'on hold',
    color: 'warning'
  },
  {
    label: 'Completed',
    value: 'completed',
    color: 'success'
  },
  {
    label: 'Closed',
    value: 'closed',
    color: 'error'
  }
];

export const STATE_TRANSITIONS = {
  assigned: [
    { label: 'WIP', value: 'work in progress', color: 'success' },
    // { label: 'Reassigned', value: 'reassigned', color: 'primary' },
    { label: 'On Hold', value: 'on hold', color: 'warning' }
  ],
  'work in progress': [
    // { label: 'Reassigned', value: 'reassigned', color: 'primary' },
    { label: 'On Hold', value: 'on hold', color: 'warning' },
    { label: 'Completed', value: 'completed', color: 'success' } //only if latest outcome is there
  ],
  reassigned: [
    { label: 'WIP', value: 'work in progress', color: 'success' },
    { label: 'On Hold', value: 'on hold', color: 'warning' }
  ],
  'on hold': [
    { label: 'WIP', value: 'work in progress', color: 'success' },
    // { label: 'Reassigned', value: 'reassigned', color: 'primary' },
    { label: 'Closed', value: 'closed', color: 'danger' } // only if no latest outcome
  ],
  completed: [],
  closed: []
};

export const TASK_STATE_TRANSITIONS = {
  // pending: [
  //   { label: 'Accept', value: 'accept', color: 'primary' },
  //   { label: 'Reject', value: 'reject', color: 'primary' }
  // ],
  assigned: [
    { label: 'In Progress', value: 'in progress', color: '#FEEFD7', fill: '#FAAE35' },
    { label: 'Rejected', value: 'rejected', color: '#FEE9E9', fill: '#FF0000' },
    { label: 'Accepted', value: 'accepted', color: '#EFF8E6', fill: '#5DB806' }
  ],
  accepted: [
    { label: 'Accepted', value: 'accepted', color: '#00FF40', fill: 'green' },
    { label: 'In Progress', value: 'in progress', color: '#FEEFD7', fill: '#FAAE35' },
    { label: 'Rejected', value: 'rejected', color: '#FEE9E9', fill: '#FF0000' }
  ],
  'in progress': [
    { label: 'In Progress', value: 'in progress', color: '#FEEFD7', fill: '#FAAE35' },
    { label: 'Completed(with Failure)', value: 'completed-wf', color: '#FEE9E9', fill: '#FF0000' },
    { label: 'Completed', value: 'completed', color: '#EFF8E6', fill: '#5DB806' },
    { label: 'Failure', value: 'failure', color: '#FEE9E9', fill: '#FF0000' }
  ],
  'completed-wf': [],
  completed: [{ label: 'Completed', value: 'completed', color: '#EFF8E6', fill: '#5DB806' }],
  closed: [],
  rejected: [],
  failure: []
};

export const TASK_STATUS = [
  { value: 'In-Progress', bgColor: '#F0E68C', fill: 'orange' },
  { value: 'Completed (With Failures)', bgColor: '#F0E68C', fill: 'red' },
  { value: 'Assigned', bgColor: '#F0E68C', fill: 'brown' },
  { value: 'Accepted', bgColor: '#00FF40', fill: 'brown' },
  { value: 'Completed (Success)', bgColor: '#00FF40', fill: 'green' },
  { value: 'Completed', bgColor: '#00FF40', fill: 'green' },
  { value: 'Rejected', bgColor: '#FEE9E9', fill: '#FF0000' }
];
export const SLA_FACTOR = [
  { value: 'Critical with Due Date', bgColor: '#FEE9E9', fill: '#FF0000' },
  { value: 'Time Sensitive', bgColor: '#E9F3FE', fill: '#0077FF' },
  { value: 'Low', bgColor: '#EFF8E6', fill: '#5DB806' },
  { value: 'Medium', bgColor: '#FEEFD7', fill: '#FAAE35' },
  { value: 'High', bgColor: '#FEE9E9', fill: '#FF0000' }
];

export const STUB_ONBOARDING = {
  data: [
    {
      assetName: 'All',
      assetPercentage: 71,
      assetType: 'All',
      sourceStatus: [
        {
          sourceId: 'azureentra',
          sourceName: 'Azure Entra',
          status: 'Success'
        },
        {
          sourceId: 'manual',
          sourceName: 'Manual Upload',
          status: 'Success'
        },
        {
          sourceId: 'manual',
          sourceName: 'Manual Upload',
          status: 'Failure'
        },
        {
          sourceId: 'microsoftclouddefender',
          sourceName: 'Microsoft Cloud Defender',
          status: 'Success'
        },
        {
          sourceId: 'browserextension',
          sourceName: null,
          status: 'Failure'
        },
        {
          sourceId: 'azureentra',
          sourceName: 'Azure Entra',
          status: 'Success'
        },
        {
          sourceId: 'manual',
          sourceName: 'Manual Upload',
          status: 'Success'
        },
        {
          sourceId: 'manual',
          sourceName: 'Manual Upload',
          status: 'Failure'
        },
        {
          sourceId: 'microsoftclouddefender',
          sourceName: 'Microsoft Cloud Defender',
          status: 'Success'
        },
        {
          sourceId: 'browserextension',
          sourceName: null,
          status: 'Failure'
        }
      ]
    },
    {
      assetType: 'user',
      assetName: 'User',
      assetPercentage: 100,
      sourceStatus: [
        {
          sourceId: 'azureentra',
          sourceName: 'Azure Entra',
          status: 'Success'
        }
      ]
    },
    {
      assetType: 'app',
      assetName: 'App',
      assetPercentage: 100,
      sourceStatus: [
        {
          sourceId: 'azureentra',
          sourceName: 'Azure Entra',
          status: 'Success'
        }
      ]
    },
    {
      assetType: 'license',
      assetName: 'License',
      assetPercentage: 100,
      sourceStatus: [
        {
          sourceId: 'azureentra',
          sourceName: 'Azure Entra',
          status: 'Success'
        },
        {
          sourceId: 'manual',
          sourceName: 'Manual Upload',
          status: 'Success'
        }
      ]
    },
    {
      assetType: 'invoice',
      assetName: 'Cost',
      assetPercentage: 0,
      sourceStatus: [
        {
          sourceId: 'manual',
          sourceName: 'Manual Upload',
          status: 'Failure'
        }
      ]
    },
    {
      assetType: 'usage',
      assetName: 'Usage',
      assetPercentage: 0,
      sourceStatus: [
        {
          sourceId: 'microsoftclouddefender',
          sourceName: 'Microsoft Cloud Defender',
          status: 'Success'
        },
        {
          sourceId: 'browserextension',
          sourceName: null,
          status: 'Failure'
        }
      ]
    }
  ]
};

export const INSIGHT_TABLE_TOOLTIP = {
  most_used_paid_apps:
    'Top 5 paid apps in the org, ordered by percentage of license utilized; number of users/number of licenses used per app. When there is a tie, the app with the higher total annual cost is chosen',
  risky_app_usage: 'Top 5 apps in the org that do not have SOC2 compliance, ordered by number of users per app in the selected time period',
  top_5_apps_cost: 'Annualized cost of the top 5 Apps. (Date filter is not applicable for this chart.)',
  top_5_category_by_cost: 'Annualized cost of the top 5 Product Categories. (Date filter is not applicable for this chart.)',
  top_5_vendors_by_cost: 'Annualized cost of the top 5 Vendors. (Date filter is not applicable for this chart.)',
  least_used_paid_apps:
    'Bottom 5 paid apps in the org, ordered by percentage of license utilized; number of users/number of licenses used per app. When there is a tie, the app with the higher total annual cost is chosen',
  most_used_apps: 'Top 5 apps in the org, ordered by number of users per app in the selected time period',
  most_used_apps_by_category: 'Top 5 product categories, ordered by number of apps per product category in the selected time period',
  most_used_apps_by_user_bytes: 'Top 5 apps in the org, ordered by employee usage (user-bytes, user-mins) in the selected time period'
};

export const STEPS_WITH_TYPE = {
  detailed: {
    assigned: ['Insight', 'Decision', 'Expected Outcome'],
    'work in progress': ['Decision', 'Expected Outcome', 'Outcome'],
    reassigned: ['Decision', 'Expected Outcome', 'Outcome'],
    'on hold': ['Decision', 'Expected Outcome', 'Outcome']
  },
  summary: {
    assigned: ['Insight', 'Decision', 'Expected Outcome'],
    'work in progress': ['Decision', 'Expected Outcome', 'Outcome'],
    reassigned: ['Decision', 'Expected Outcome', 'Outcome'],
    'on hold': ['Decision', 'Expected Outcome', 'Outcome']
  },
  history: {
    completed: ['Decision', 'Expected Outcome', 'Outcome'],
    closed: ['Decision', 'Expected Outcome', 'Outcome']
  }
};

export const DECISION_SLIDER_MARKS = [
  {
    value: -101
    // label: 'Less than expected'
  },
  {
    value: 0
    // label: '0% Achieved'
  },
  {
    value: 100
    // label: '100% Achieved'
  },
  {
    value: 101
    // label: 'More than expected'
  }
];

export const DATE_RANGES = {
  last7: { startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD') },
  last30: { startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD') },
  last90: { startDate: moment().subtract(90, 'days').format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD') }
};

export const GROUP_BY_KEYS_TABLE = {
  apps_top10_paid_avg_cost: 'appName',
  apps_paid_with_usage: 'appName',
  apps_paid_no_usage: 'appName',
  cost_paid_apps_no_usage: 'appName'
};

export const REACT_QUILL_CONFIG = {
  toolbar: [['bold', 'italic', 'underline'], [{ list: 'ordered' }, { list: 'bullet' }], [{ indent: '-1' }, { indent: '+1' }], ['link']]
};

export const REACT_QUILL_FORMATS = ['bold', 'italic', 'underline', 'list', 'bullet', 'indent', 'link'];

export const MOCK_DATA_FG = {
  nodes: [
    {
      id: 1,
      name: 'Node 1'
    },
    { id: 2, name: 'Node 2', color: 'red', size: 10 },
    {
      id: 7,
      name: 'Node 7',
      color: 'green',
      size: 10
    },
    { id: 13, name: 'Node 13' },
    { id: 14, name: 'Node 14' }
  ],
  links: [
    { source: 1, target: 2, id: '1-2' },
    { source: 1, target: 7, id: '1-7' },
    { source: 1, target: 13, id: '1-13' },
    { source: 1, target: 14, id: '1-14' }
  ]
};

export const MOCK_DATA_FG_3D = {
  nodes: [
    { id: '1', label: 'Customer', name: 'Acme Corp', location: 'New York', color: '#1f77b4', size: 15 },
    { id: '2', label: 'Customer', name: 'Global Enterprises', location: 'London', color: '#1f77b4', size: 15 },

    { id: '10', label: 'User', name: 'John Doe', email: 'john.doe@example.com', color: '#ff7f0e', size: 10 },
    { id: '11', label: 'User', name: 'Jane Smith', email: 'jane.smith@example.com', color: '#ff7f0e', size: 10 },

    { id: '20', label: 'Access', type: 'Admin', level: 'Full', color: '#2ca02c', size: 8 },
    { id: '21', label: 'Access', type: 'User', level: 'Read-Only', color: '#2ca02c', size: 8 },

    { id: '30', label: 'Company', name: 'Google', industry: 'Technology', color: '#d62728', size: 12 },
    { id: '31', label: 'Company', name: 'Microsoft', industry: 'Technology', color: '#d62728', size: 12 },

    { id: '40', label: 'Asset (App)', name: 'SalesForce', description: 'CRM software', color: '#9467bd', size: 14 },
    { id: '41', label: 'Asset (App)', name: 'Office 365', description: 'Productivity suite', color: '#9467bd', size: 14 },

    {
      id: '50',
      label: 'Vendor (Reseller)',
      name: 'Tech Solutions Ltd.',
      url: 'https://www.techsolutions.com',
      description: 'Leading software reseller',
      location: 'San Francisco',
      duns: '123456789',
      color: '#8c564b',
      size: 13
    },
    {
      id: '51',
      label: 'Vendor (Reseller)',
      name: 'IT Supplies Inc.',
      url: 'https://www.itsupplies.com',
      description: 'Hardware and software distributor',
      location: 'Austin',
      duns: '987654321',
      color: '#8c564b',
      size: 13
    },

    {
      id: '60',
      label: 'Source',
      name: 'Public Records',
      type: 'Public',
      description: 'Government published data',
      url: 'https://data.gov',
      color: '#e377c2',
      size: 9
    },
    {
      id: '61',
      label: 'Source',
      name: 'Company Reports',
      type: 'Internal',
      description: 'Internal financial reports',
      url: 'https://acme.com/reports',
      color: '#e377c2',
      size: 9
    },

    { id: '70', label: 'Ownership', name: 'Product Ownership', type: 'Asset', source: 'Public Records', color: '#7f7f7f', size: 7 },

    {
      id: '80',
      label: 'License',
      company: 'Google',
      asset: 'SalesForce',
      users: 100,
      admin_users: 10,
      start_date: '2024-01-01',
      end_date: '2025-01-01',
      status: 'Active',
      total_cost: 10000,
      currency: 'USD',
      account_rep: 'Alice Johnson',
      email: 'alice.johnson@google.com',
      color: '#bcbd22',
      size: 11
    },

    {
      id: '90',
      label: 'Invoice',
      license: 'SalesForce',
      company: 'Google',
      period: '2024-01',
      date: '2024-02-01',
      billing_method: 'Electronic',
      currency: 'USD',
      color: '#17becf',
      size: 10
    },

    {
      id: '100',
      label: 'Usage',
      customer: 'Acme Corp',
      user: 'John Doe',
      asset: 'SalesForce',
      usage_date: '2024-06-01',
      unit: 'Hours',
      quantity: 5,
      color: '#9edae5',
      size: 8
    }
  ],
  links: [
    { source: '1', target: '10', relationship: 'Customer_User' },
    { source: '1', target: '20', relationship: 'Customer_Access' },
    { source: '1', target: '30', relationship: 'Customer_Company' },
    { source: '1', target: '40', relationship: 'Customer_Asset' },
    { source: '1', target: '50', relationship: 'Customer_Vendor' },
    { source: '1', target: '60', relationship: 'Customer_Sources' },
    { source: '1', target: '80', relationship: 'Customer_License' },
    { source: '1', target: '90', relationship: 'Customer_Invoice' },
    { source: '1', target: '100', relationship: 'Customer_Usage' },

    { source: '2', target: '11', relationship: 'Customer_User' },
    { source: '2', target: '21', relationship: 'Customer_Access' },
    { source: '2', target: '31', relationship: 'Customer_Company' },
    { source: '2', target: '41', relationship: 'Customer_Asset' },
    { source: '2', target: '51', relationship: 'Customer_Vendor' },
    { source: '2', target: '61', relationship: 'Customer_Sources' },
    { source: '2', target: '80', relationship: 'Customer_License' },
    { source: '2', target: '90', relationship: 'Customer_Invoice' },
    { source: '2', target: '100', relationship: 'Customer_Usage' },

    { source: '10', target: '20', relationship: 'User_Access' },
    { source: '10', target: '11', relationship: 'User_User' },
    { source: '10', target: '40', relationship: 'User_Asset' },
    { source: '10', target: '60', relationship: 'User_Sources' },
    { source: '10', target: '100', relationship: 'User_Usage' },

    { source: '11', target: '21', relationship: 'User_Access' },
    { source: '11', target: '41', relationship: 'User_Asset' },
    { source: '11', target: '61', relationship: 'User_Sources' },
    { source: '11', target: '100', relationship: 'User_Usage' },

    { source: '30', target: '40', relationship: 'Company_Asset' },
    { source: '30', target: '50', relationship: 'Company_Vendor' },
    { source: '30', target: '60', relationship: 'Company_Sources' },

    { source: '31', target: '41', relationship: 'Company_Asset' },
    { source: '31', target: '51', relationship: 'Company_Vendor' },
    { source: '31', target: '61', relationship: 'Company_Sources' },

    { source: '40', target: '41', relationship: 'Asset_Asset' },
    { source: '40', target: '70', relationship: 'Asset_Ownership' },
    { source: '40', target: '50', relationship: 'Asset_Vendor' },
    { source: '40', target: '60', relationship: 'Asset_Sources' },
    { source: '40', target: '80', relationship: 'Asset_License' },
    { source: '40', target: '90', relationship: 'Asset_Invoice' },
    { source: '40', target: '100', relationship: 'Asset_Usage' },

    { source: '41', target: '70', relationship: 'Asset_Ownership' },
    { source: '41', target: '51', relationship: 'Asset_Vendor' },
    { source: '41', target: '61', relationship: 'Asset_Sources' },
    { source: '41', target: '80', relationship: 'Asset_License' },
    { source: '41', target: '90', relationship: 'Asset_Invoice' },
    { source: '41', target: '100', relationship: 'Asset_Usage' },

    { source: '50', target: '60', relationship: 'Vendor_Source' },
    { source: '50', target: '80', relationship: 'Vendor_License' },
    { source: '50', target: '90', relationship: 'Vendor_Invoice' },

    { source: '51', target: '61', relationship: 'Vendor_Source' },
    { source: '51', target: '80', relationship: 'Vendor_License' },
    { source: '51', target: '90', relationship: 'Vendor_Invoice' },

    { source: '80', target: '90', relationship: 'License_Invoice' }
  ]
};

export const MOCK_DATA_AFG = {
  name: 'Root',
  value: 0,
  children: [
    {
      name: '1',
      linkWith: ['2'],
      children: [
        {
          name: 'A',
          children: [
            { name: 'A1', value: 1 },
            { name: 'A2', value: 1 },
            { name: 'A3', value: 1 },
            { name: 'A4', value: 1 },
            { name: 'A5', value: 1 }
          ]
        },
        {
          name: 'B',
          children: [
            { name: 'B1', value: 1 },
            { name: 'B2', value: 1 },
            { name: 'B3', value: 1 },
            { name: 'B4', value: 1 },
            { name: 'B5', value: 1 }
          ]
        },
        {
          name: 'C',
          children: [
            { name: 'C1', value: 1 },
            { name: 'C2', value: 1 },
            { name: 'C3', value: 1 },
            { name: 'C4', value: 1 },
            { name: 'C5', value: 1 }
          ]
        }
      ]
    },

    {
      name: '2',
      linkWith: ['3'],
      children: [
        {
          name: 'D',
          value: 1
        },
        {
          name: 'E',
          value: 1
        }
      ]
    },
    {
      name: '3',
      children: [
        {
          name: 'F',
          children: [
            { name: 'F1', value: 1 },
            { name: 'F2', value: 1 },
            { name: 'F3', value: 1 },
            { name: 'F4', value: 1 },
            { name: 'F5', value: 1 }
          ]
        },
        {
          name: 'H',
          children: [
            { name: 'H1', value: 1 },
            { name: 'H2', value: 1 },
            { name: 'H3', value: 1 },
            { name: 'H4', value: 1 },
            { name: 'H5', value: 1 }
          ]
        },
        {
          name: 'G',
          children: [
            { name: 'G1', value: 1 },
            { name: 'G2', value: 1 },
            { name: 'G3', value: 1 },
            { name: 'G4', value: 1 },
            { name: 'G5', value: 1 }
          ]
        }
      ]
    }
  ]
};

export const BAR_CHART_STUB = [
  {
    icon: 'https://stbackendserviceprod.blob.core.windows.net/icons/Microsoft SharePoint Online.svg',
    xaxisValue: 'Microsoft SharePoint Online',
    yaxisValue: 590
  },
  {
    icon: 'https://stbackendserviceprod.blob.core.windows.net/icons/Office Portal.svg',
    xaxisValue: 'Office Portal',
    yaxisValue: 357
  },
  {
    icon: 'https://stbackendserviceprod.blob.core.windows.net/icons/MSN.svg',
    xaxisValue: 'MSN',
    yaxisValue: 354
  },
  {
    icon: 'https://stbackendserviceprod.blob.core.windows.net/icons/Microsoft Defender SmartScreen.svg',
    xaxisValue: 'Microsoft Defender SmartScreen',
    yaxisValue: 335
  },
  {
    icon: 'https://stbackendserviceprod.blob.core.windows.net/icons/Azure Content Delivery Network.svg',
    xaxisValue: 'Azure Content Delivery Network',
    yaxisValue: 331
  },
  {
    icon: 'https://stbackendserviceprod.blob.core.windows.net/icons/Microsoft Graph.svg',
    xaxisValue: 'Microsoft Graph',
    yaxisValue: 316
  },
  {
    icon: 'https://stbackendserviceprod.blob.core.windows.net/icons/Skype for Business.svg',
    xaxisValue: 'Skype for Business',
    yaxisValue: 312
  },
  {
    icon: 'https://stbackendserviceprod.blob.core.windows.net/icons/Microsoft Exchange Online.svg',
    xaxisValue: 'Microsoft Exchange Online',
    yaxisValue: 305
  },
  {
    icon: 'https://stbackendserviceprod.blob.core.windows.net/icons/Microsoft Store.svg',
    xaxisValue: 'Microsoft Store',
    yaxisValue: 302
  },
  {
    icon: 'https://stbackendserviceprod.blob.core.windows.net/icons/Microsoft OneDrive for Business.svg',
    xaxisValue: 'Microsoft OneDrive for Business',
    yaxisValue: 298
  }
];

export const VARIANCE_CHART_BENCHMARK_OU = {
  id: 'Top_Over_Provisioned',
  icon: '',
  type: 'negative',
  info: 'Top 10 Over Provisioned Clinics, ordered by variance',
  Title: 'Top Over Provisioned Clinics',
  category: 'Benchmark',
  isEnabled: true,
  valueLabel: null,
  chartType: 'variancechart',
  value: {
    values: {
      'West Cobb': 41,
      Medina: 34,
      Senoia: 32,
      'Verdae (Greenville)': 29,
      'East Point': 28,
      Powdersville: 28,
      Fayetteville: 28,
      'Ponce De Leon': 26,
      Austell: 25,
      'West Bloomfield': 20
    },
    Variance: {
      'West Cobb': 27,
      Medina: 20,
      Senoia: 16,
      'Verdae (Greenville)': 15,
      'East Point': 14,
      Powdersville: 14,
      Fayetteville: 12,
      'Ponce De Leon': 12,
      Austell: 11,
      'West Bloomfield': 11
    }
  },
  footers: [],
  insightText: 'The total number of exceeded assets across all over-provisioned Clinics are 635',
  xaxisTitle: 'Clinic Name',
  yaxisTitle: 'Number of Assets',
  xaxisValueLabel: null,
  yaxisValueLabel: null
};

export const VARIANCE_CHART_BENCHMARK_UU = {
  id: 'Top_Under_Provisioned',
  icon: '',
  type: 'positive',
  info: 'Top 10 Under Provisioned Clinics, ordered by variance',
  Title: 'Top Under Provisioned Clinics',
  category: 'Benchmark',
  isEnabled: true,
  valueLabel: null,
  chartType: 'variancechart',
  value: {
    values: {
      'Broadview Heights': 3,
      'Columbus - Uptown': 8,
      'Cumming Tribble Road': 8,
      'Shaker Heights': 6,
      Ellenwood: 12,
      'Clairmont/Druid Hills': 12,
      Lawrenceville: 12,
      Conyers: 15,
      'Snellville-Centerville Hwy': 11,
      Avon: 8
    },
    Variance: {
      'Broadview Heights': 6,
      'Columbus - Uptown': 4,
      'Cumming Tribble Road': 4,
      'Shaker Heights': 3,
      Ellenwood: 2,
      'Clairmont/Druid Hills': 2,
      Lawrenceville: 2,
      Conyers: 1,
      'Snellville-Centerville Hwy': 1,
      Avon: 1
    }
  },
  footers: [],
  insightText: 'The total number of additional assets required across all under-provisioned Clinics are 30',
  xaxisTitle: 'Clinic Name',
  yaxisTitle: 'Number of Assets',
  xaxisValueLabel: null,
  yaxisValueLabel: null
};

export const WATERFALL_CHART_BENCHMARK_CAU = {
  format: [
    {
      y_axis_format: 'currency'
    }
  ],
  id: 'amount_saving_across_tier',
  icon: null,
  title: 'Current vs Optimized cost across tiers',
  info: 'Bars indicate cost per license tier',
  chartValues: [
    {
      category: 'Optimized',
      'F3(Teams Desktop + Sec)': 224588.16,
      F1: 13230,
      'Not active': 0,
      'F3(Teams Online + Sec)': 73051.68,
      E5: 751272
    },
    {
      category: 'Current',
      E5: 1531800,
      'F3(Teams Online + Sec)': 117517.92,
      'F3(Teams Desktop + Sec)': null,
      F1: null,
      'Not active': null
    }
  ],
  groupedValues: null,
  toolTip: null,
  isEnabled: true,
  type: 'stackedbarchart',
  xAxisTitle: ' License Types',
  yAxisTitle: 'Annual Cost ($)',
  description: null,
  insightText: ["Action on a total of inactive and unactivated licenses can amount to a potential cost-saving avenue of '$587.18K’."],
  category: 'bundle_optimization',
  displayType: null,
  valueLabel: null,
  yAxisTitle1: null,
  yAxisTitle2: null,
  dataProductId: null,
  lineSeries: null,
  insightId: null,
  isPositive: null
};

export const LINE_BAR_CHART_OPTIMIZATION = {
  Title: 'Microsoft Office 365 Optimisation',
  Y1_axis: '# of Licenses',
  Y2_axis: 'Annual Cost ($)',
  X_axis: ['Now', 'Optimised'],
  value: [
    {
      category: 'Now',
      E5: 2775,
      'F3+': 225,
      'Annual Cost': 1605000
    },
    {
      category: 'Optimised',
      E5: 500,
      'F3+': 2500,
      'Annual Cost': 760500
    }
  ],
  Textual_Insight: 'Optimising your Microsoft Office 365 licenses allocation will save you $0.9M annually'
};

export const CLINIC_ASSET_TABLE_DATA = {
  headers: [
    {
      id: 'name',
      label: 'Site Code',
      title: 'Site Code',
      type: 'string',
      display: true,
      key: 'name'
    },
    {
      id: 'count',
      label: 'Count',
      title: 'Count',
      type: 'number',
      display: true,
      key: 'count'
    },
    {
      id: 'tier1',
      label: 'Tier',
      title: 'Tier',
      type: 'string',
      display: true,
      key: 'tier1'
    },
    {
      id: 'avg_visit_count',
      label: 'Avg Visit Count',
      title: 'Avg Visit Count',
      type: 'number',
      display: true,
      key: 'avg_visit_count'
    },
    {
      id: 'tier2',
      label: 'Tier',
      title: 'Tier',
      type: 'string',
      display: true,
      key: 'tier2'
    },
    {
      id: 'projected_compute',
      label: 'Projected Compute',
      title: 'Projected Compute',
      type: 'number',
      display: true,
      key: 'projected_compute'
    },
    {
      id: 'variance',
      label: 'Variance',
      title: 'Variance',
      type: 'number',
      display: true,
      key: 'variance'
    },
    {
      id: 'variance_as_percentage',
      label: 'Variance as %',
      title: 'Variance as %',
      type: 'string',
      display: true,
      key: 'variance_as_percentage'
    }
  ],
  data: [
    {
      name: 'WSK',
      count: 12,
      tier1: 'Tier 4',
      avg_visit_count: 31,
      tier2: 'Tier 2',
      projected_compute: 10,
      variance: -2,
      variance_as_percentage: '(20.0%)'
    },
    {
      name: 'BFD',
      count: 10,
      tier1: 'Tier 2',
      avg_visit_count: 28,
      tier2: 'Tier 1',
      projected_compute: 7,
      variance: -3,
      variance_as_percentage: '(42.9%)'
    },
    {
      name: 'CBE',
      count: 13,
      tier1: 'Tier 4',
      avg_visit_count: 31,
      tier2: 'Tier 2',
      projected_compute: 10,
      variance: -3,
      variance_as_percentage: '(30.0%)'
    },
    {
      name: 'CMA',
      count: 12,
      tier1: 'Tier 4',
      avg_visit_count: 33,
      tier2: 'Tier 2',
      projected_compute: 10,
      variance: -2,
      variance_as_percentage: '(20.0%)'
    },
    {
      name: 'CTF',
      count: 13,
      tier1: 'Tier 4',
      avg_visit_count: 31,
      tier2: 'Tier 2',
      projected_compute: 10,
      variance: -3,
      variance_as_percentage: '(30.0%)'
    },
    {
      name: 'CYR',
      count: 11,
      tier1: 'Tier 3',
      avg_visit_count: 50,
      tier2: 'Tier 3',
      projected_compute: 11,
      variance: 0,
      variance_as_percentage: '0.0%'
    },
    {
      name: 'HBR',
      count: 10,
      tier1: 'Tier 2',
      avg_visit_count: 27,
      tier2: 'Tier 1',
      projected_compute: 7,
      variance: -3,
      variance_as_percentage: '(42.9%)'
    },
    {
      name: 'PTS',
      count: 13,
      tier1: 'Tier 4',
      avg_visit_count: 42,
      tier2: 'Tier 3',
      projected_compute: 11,
      variance: -2,
      variance_as_percentage: '(18.2%)'
    },
    {
      name: 'SCS',
      count: 14,
      tier1: 'Tier 5',
      avg_visit_count: 43,
      tier2: 'Tier 3',
      projected_compute: 11,
      variance: -3,
      variance_as_percentage: '(27.3%)'
    },
    {
      name: 'SNA',
      count: 14,
      tier1: 'Tier 5',
      avg_visit_count: 47,
      tier2: 'Tier 3',
      projected_compute: 11,
      variance: -3,
      variance_as_percentage: '(27.3%)'
    },
    {
      name: 'SSV',
      count: 11,
      tier1: 'Tier 3',
      avg_visit_count: 34,
      tier2: 'Tier 2',
      projected_compute: 10,
      variance: -1,
      variance_as_percentage: '(10.0%)'
    },
    {
      name: 'ASH',
      count: 8,
      tier1: 'Tier 2',
      avg_visit_count: 21,
      tier2: 'Tier 1',
      projected_compute: 7,
      variance: -1,
      variance_as_percentage: '(14.3%)'
    },
    {
      name: 'BKM',
      count: 12,
      tier1: 'Tier 4',
      avg_visit_count: 39,
      tier2: 'Tier 2',
      projected_compute: 10,
      variance: -2,
      variance_as_percentage: '(20.0%)'
    },
    {
      name: 'COM',
      count: 12,
      tier1: 'Tier 4',
      avg_visit_count: 37,
      tier2: 'Tier 2',
      projected_compute: 10,
      variance: -2,
      variance_as_percentage: '(20.0%)'
    },
    {
      name: 'GRY',
      count: 9,
      tier1: 'Tier 2',
      avg_visit_count: 23,
      tier2: 'Tier 1',
      projected_compute: 7,
      variance: -2,
      variance_as_percentage: '(28.6%)'
    },
    {
      name: 'MED',
      count: 13,
      tier1: 'Tier 4',
      avg_visit_count: 41,
      tier2: 'Tier 3',
      projected_compute: 11,
      variance: -2,
      variance_as_percentage: '(18.2%)'
    },
    {
      name: 'MJR',
      count: 9,
      tier1: 'Tier 2',
      avg_visit_count: 25,
      tier2: 'Tier 1',
      projected_compute: 7,
      variance: -2,
      variance_as_percentage: '(28.6%)'
    },
    {
      name: 'MOM',
      count: 4,
      tier1: 'Tier 1',
      avg_visit_count: 0,
      tier2: 'Tier 1',
      projected_compute: 7,
      variance: 3,
      variance_as_percentage: '42.9%'
    },
    {
      name: 'MTV',
      count: 10,
      tier1: 'Tier 2',
      avg_visit_count: 35,
      tier2: 'Tier 2',
      projected_compute: 10,
      variance: 0,
      variance_as_percentage: '0.0%'
    },
    {
      name: 'SNV',
      count: 12,
      tier1: 'Tier 4',
      avg_visit_count: 32,
      tier2: 'Tier 2',
      projected_compute: 10,
      variance: -2,
      variance_as_percentage: '(20.0%)'
    },
    {
      name: 'TLR',
      count: 12,
      tier1: 'Tier 4',
      avg_visit_count: 31,
      tier2: 'Tier 2',
      projected_compute: 10,
      variance: -2,
      variance_as_percentage: '(20.0%)'
    },
    {
      name: 'TWN',
      count: 11,
      tier1: 'Tier 3',
      avg_visit_count: 31,
      tier2: 'Tier 2',
      projected_compute: 10,
      variance: -1,
      variance_as_percentage: '(10.0%)'
    },
    {
      name: 'CTR',
      count: 7,
      tier1: 'Tier 1',
      avg_visit_count: 30,
      tier2: 'Tier 1',
      projected_compute: 7,
      variance: 0,
      variance_as_percentage: '0.0%'
    },
    {
      name: 'DUN',
      count: 7,
      tier1: 'Tier 1',
      avg_visit_count: 21,
      tier2: 'Tier 1',
      projected_compute: 7,
      variance: 0,
      variance_as_percentage: '0.0%'
    },
    {
      name: 'FBN',
      count: 12,
      tier1: 'Tier 4',
      avg_visit_count: 51,
      tier2: 'Tier 3',
      projected_compute: 11,
      variance: -1,
      variance_as_percentage: '(9.1%)'
    },
    {
      name: 'FHN',
      count: 8,
      tier1: 'Tier 2',
      avg_visit_count: 20,
      tier2: 'Tier 1',
      projected_compute: 7,
      variance: -1,
      variance_as_percentage: '(14.3%)'
    },
    {
      name: 'MNT',
      count: 10,
      tier1: 'Tier 2',
      avg_visit_count: 34,
      tier2: 'Tier 2',
      projected_compute: 10,
      variance: 0,
      variance_as_percentage: '0.0%'
    },
    {
      name: 'OCN',
      count: 11,
      tier1: 'Tier 3',
      avg_visit_count: 37,
      tier2: 'Tier 2',
      projected_compute: 10,
      variance: -1,
      variance_as_percentage: '(10.0%)'
    },
    {
      name: 'WHN',
      count: 11,
      tier1: 'Tier 3',
      avg_visit_count: 35,
      tier2: 'Tier 2',
      projected_compute: 10,
      variance: -1,
      variance_as_percentage: '(10.0%)'
    },
    {
      name: 'CMT',
      count: 10,
      tier1: 'Tier 2',
      avg_visit_count: 39,
      tier2: 'Tier 2',
      projected_compute: 10,
      variance: 0,
      variance_as_percentage: '0.0%'
    },
    {
      name: 'PAN',
      count: 9,
      tier1: 'Tier 2',
      avg_visit_count: 31,
      tier2: 'Tier 2',
      projected_compute: 10,
      variance: 1,
      variance_as_percentage: '10.0%'
    },
    {
      name: 'REA',
      count: 7,
      tier1: 'Tier 1',
      avg_visit_count: 25,
      tier2: 'Tier 1',
      projected_compute: 7,
      variance: 0,
      variance_as_percentage: '0.0%'
    },
    {
      name: 'SLN',
      count: 6,
      tier1: 'Tier 1',
      avg_visit_count: 30,
      tier2: 'Tier 1',
      projected_compute: 7,
      variance: 1,
      variance_as_percentage: '14.3%'
    },
    {
      name: 'EWD',
      count: 9,
      tier1: 'Tier 2',
      avg_visit_count: 45,
      tier2: 'Tier 3',
      projected_compute: 11,
      variance: 2,
      variance_as_percentage: '18.2%'
    },
    {
      name: 'LIT',
      count: 9,
      tier1: 'Tier 2',
      avg_visit_count: 36,
      tier2: 'Tier 2',
      projected_compute: 10,
      variance: 1,
      variance_as_percentage: '10.0%'
    },
    {
      name: 'RWE',
      count: 9,
      tier1: 'Tier 2',
      avg_visit_count: 37,
      tier2: 'Tier 2',
      projected_compute: 10,
      variance: 1,
      variance_as_percentage: '10.0%'
    },
    {
      name: 'SDB',
      count: 9,
      tier1: 'Tier 2',
      avg_visit_count: 31,
      tier2: 'Tier 2',
      projected_compute: 10,
      variance: 1,
      variance_as_percentage: '10.0%'
    },
    {
      name: 'BHT',
      count: 4,
      tier1: 'Tier 1',
      avg_visit_count: 27,
      tier2: 'Tier 1',
      projected_compute: 7,
      variance: 3,
      variance_as_percentage: '42.9%'
    },
    {
      name: 'LAW',
      count: 7,
      tier1: 'Tier 1',
      avg_visit_count: 36,
      tier2: 'Tier 2',
      projected_compute: 10,
      variance: 3,
      variance_as_percentage: '30.0%'
    },
    {
      name: 'UTN',
      count: 7,
      tier1: 'Tier 1',
      avg_visit_count: 33,
      tier2: 'Tier 2',
      projected_compute: 10,
      variance: 3,
      variance_as_percentage: '30.0%'
    },
    {
      name: 'SHH',
      count: 3,
      tier1: 'Tier 1',
      avg_visit_count: 24,
      tier2: 'Tier 1',
      projected_compute: 7,
      variance: 4,
      variance_as_percentage: '57.1%'
    },
    {
      name: 'WKV',
      count: 2,
      tier1: 'Tier 1',
      avg_visit_count: 22,
      tier2: 'Tier 1',
      projected_compute: 7,
      variance: 5,
      variance_as_percentage: '71.4%'
    },
    {
      name: 'ACW',
      count: 2,
      tier1: 'Tier 1',
      avg_visit_count: 33,
      tier2: 'Tier 2',
      projected_compute: 10,
      variance: 8,
      variance_as_percentage: '80.0%'
    },
    {
      name: 'ATN',
      count: 1,
      tier1: 'Tier 1',
      avg_visit_count: 44,
      tier2: 'Tier 3',
      projected_compute: 11,
      variance: 10,
      variance_as_percentage: '90.9%'
    },
    {
      name: 'ADR',
      count: 8,
      tier1: 'Tier 2',
      avg_visit_count: null,
      tier2: null,
      projected_compute: null,
      variance: null,
      variance_as_percentage: null
    },
    {
      name: 'CTN',
      count: 28,
      tier1: 'Tier 6',
      avg_visit_count: null,
      tier2: null,
      projected_compute: null,
      variance: null,
      variance_as_percentage: null
    },
    {
      name: 'ECB',
      count: 12,
      tier1: 'Tier 4',
      avg_visit_count: null,
      tier2: null,
      projected_compute: null,
      variance: null,
      variance_as_percentage: null
    },
    {
      name: 'HQ',
      count: 38,
      tier1: 'Tier 6',
      avg_visit_count: null,
      tier2: null,
      projected_compute: null,
      variance: null,
      variance_as_percentage: null
    },
    {
      name: 'JCK',
      count: 11,
      tier1: 'Tier 3',
      avg_visit_count: null,
      tier2: null,
      projected_compute: null,
      variance: null,
      variance_as_percentage: null
    },
    {
      name: 'RCM',
      count: 128,
      tier1: 'Tier 6',
      avg_visit_count: null,
      tier2: null,
      projected_compute: null,
      variance: null,
      variance_as_percentage: null
    },
    {
      name: 'STF',
      count: 16,
      tier1: 'Tier 6',
      avg_visit_count: null,
      tier2: null,
      projected_compute: null,
      variance: null,
      variance_as_percentage: null
    },
    {
      name: 'UC & OM only',
      count: 70,
      tier1: 'Tier 6',
      avg_visit_count: null,
      tier2: null,
      projected_compute: null,
      variance: null,
      variance_as_percentage: null
    },
    {
      name: 'VER',
      count: 25,
      tier1: 'Tier 6',
      avg_visit_count: null,
      tier2: null,
      projected_compute: null,
      variance: null,
      variance_as_percentage: null
    },
    {
      name: 'WLB',
      count: 8,
      tier1: 'Tier 2',
      avg_visit_count: null,
      tier2: null,
      projected_compute: null,
      variance: null,
      variance_as_percentage: null
    },
    {
      name: 'JFN',
      count: 31,
      tier1: 'Tier 6',
      avg_visit_count: 36,
      tier2: 'Tier 2',
      projected_compute: 10,
      variance: -21,
      variance_as_percentage: '(210.0%)'
    },
    {
      name: 'WBF',
      count: 28,
      tier1: 'Tier 6',
      avg_visit_count: 21,
      tier2: 'Tier 1',
      projected_compute: 7,
      variance: -21,
      variance_as_percentage: '(300.0%)'
    },
    {
      name: 'WCB',
      count: 27,
      tier1: 'Tier 6',
      avg_visit_count: 41,
      tier2: 'Tier 3',
      projected_compute: 11,
      variance: -16,
      variance_as_percentage: '(145.5%)'
    },
    {
      name: 'PDL',
      count: 22,
      tier1: 'Tier 6',
      avg_visit_count: 50,
      tier2: 'Tier 3',
      projected_compute: 11,
      variance: -11,
      variance_as_percentage: '(100.0%)'
    },
    {
      name: 'CVT',
      count: 23,
      tier1: 'Tier 6',
      avg_visit_count: 53,
      tier2: 'Tier 3',
      projected_compute: 11,
      variance: -12,
      variance_as_percentage: '(109.1%)'
    },
    {
      name: 'HTS',
      count: 20,
      tier1: 'Tier 6',
      avg_visit_count: 25,
      tier2: 'Tier 1',
      projected_compute: 7,
      variance: -13,
      variance_as_percentage: '(185.7%)'
    },
    {
      name: 'CRL',
      count: 20,
      tier1: 'Tier 6',
      avg_visit_count: 41,
      tier2: 'Tier 3',
      projected_compute: 11,
      variance: -9,
      variance_as_percentage: '(81.8%)'
    },
    {
      name: 'EPT',
      count: 20,
      tier1: 'Tier 6',
      avg_visit_count: 48,
      tier2: 'Tier 3',
      projected_compute: 11,
      variance: -9,
      variance_as_percentage: '(81.8%)'
    },
    {
      name: 'FYT',
      count: 20,
      tier1: 'Tier 6',
      avg_visit_count: 54,
      tier2: 'Tier 3',
      projected_compute: 11,
      variance: -9,
      variance_as_percentage: '(81.8%)'
    },
    {
      name: 'HFT',
      count: 18,
      tier1: 'Tier 6',
      avg_visit_count: 27,
      tier2: 'Tier 1',
      projected_compute: 7,
      variance: -11,
      variance_as_percentage: '(157.1%)'
    },
    {
      name: 'MCD',
      count: 15,
      tier1: 'Tier 5',
      avg_visit_count: 59,
      tier2: 'Tier 3',
      projected_compute: 11,
      variance: -4,
      variance_as_percentage: '(36.4%)'
    },
    {
      name: 'BRW',
      count: 21,
      tier1: 'Tier 6',
      avg_visit_count: 43,
      tier2: 'Tier 3',
      projected_compute: 11,
      variance: -10,
      variance_as_percentage: '(90.9%)'
    },
    {
      name: 'DLS',
      count: 14,
      tier1: 'Tier 5',
      avg_visit_count: 51,
      tier2: 'Tier 3',
      projected_compute: 11,
      variance: -3,
      variance_as_percentage: '(27.3%)'
    },
    {
      name: 'PHL',
      count: 17,
      tier1: 'Tier 6',
      avg_visit_count: 27,
      tier2: 'Tier 1',
      projected_compute: 7,
      variance: -10,
      variance_as_percentage: '(142.9%)'
    },
    {
      name: 'BFT',
      count: 15,
      tier1: 'Tier 5',
      avg_visit_count: 17,
      tier2: 'Tier 1',
      projected_compute: 7,
      variance: -8,
      variance_as_percentage: '(114.3%)'
    },
    {
      name: 'BHP',
      count: 18,
      tier1: 'Tier 6',
      avg_visit_count: 41,
      tier2: 'Tier 3',
      projected_compute: 11,
      variance: -7,
      variance_as_percentage: '(63.6%)'
    },
    {
      name: 'CHB',
      count: 16,
      tier1: 'Tier 6',
      avg_visit_count: 14,
      tier2: 'Tier 1',
      projected_compute: 7,
      variance: -9,
      variance_as_percentage: '(128.6%)'
    },
    {
      name: 'FVN',
      count: 20,
      tier1: 'Tier 6',
      avg_visit_count: 41,
      tier2: 'Tier 3',
      projected_compute: 11,
      variance: -9,
      variance_as_percentage: '(81.8%)'
    },
    {
      name: 'GRE',
      count: 20,
      tier1: 'Tier 6',
      avg_visit_count: 45,
      tier2: 'Tier 3',
      projected_compute: 11,
      variance: -9,
      variance_as_percentage: '(81.8%)'
    },
    {
      name: 'MCI',
      count: 14,
      tier1: 'Tier 5',
      avg_visit_count: 34,
      tier2: 'Tier 2',
      projected_compute: 10,
      variance: -4,
      variance_as_percentage: '(40.0%)'
    },
    {
      name: 'NOR',
      count: 14,
      tier1: 'Tier 5',
      avg_visit_count: 22,
      tier2: 'Tier 1',
      projected_compute: 7,
      variance: -7,
      variance_as_percentage: '(100.0%)'
    },
    {
      name: 'SIM',
      count: 19,
      tier1: 'Tier 6',
      avg_visit_count: 41,
      tier2: 'Tier 3',
      projected_compute: 11,
      variance: -8,
      variance_as_percentage: '(72.7%)'
    },
    {
      name: 'WRE',
      count: 19,
      tier1: 'Tier 6',
      avg_visit_count: 33,
      tier2: 'Tier 2',
      projected_compute: 10,
      variance: -9,
      variance_as_percentage: '(90.0%)'
    },
    {
      name: 'ALP',
      count: 15,
      tier1: 'Tier 5',
      avg_visit_count: 27,
      tier2: 'Tier 1',
      projected_compute: 7,
      variance: -8,
      variance_as_percentage: '(114.3%)'
    },
    {
      name: 'BWS',
      count: 18,
      tier1: 'Tier 6',
      avg_visit_count: 41,
      tier2: 'Tier 3',
      projected_compute: 11,
      variance: -7,
      variance_as_percentage: '(63.6%)'
    },
    {
      name: 'GWR',
      count: 15,
      tier1: 'Tier 5',
      avg_visit_count: 24,
      tier2: 'Tier 1',
      projected_compute: 7,
      variance: -8,
      variance_as_percentage: '(114.3%)'
    },
    {
      name: 'LGN',
      count: 19,
      tier1: 'Tier 6',
      avg_visit_count: 44,
      tier2: 'Tier 3',
      projected_compute: 11,
      variance: -8,
      variance_as_percentage: '(72.7%)'
    },
    {
      name: 'RHS',
      count: 18,
      tier1: 'Tier 6',
      avg_visit_count: 35,
      tier2: 'Tier 2',
      projected_compute: 10,
      variance: -8,
      variance_as_percentage: '(80.0%)'
    },
    {
      name: 'TBB',
      count: 18,
      tier1: 'Tier 6',
      avg_visit_count: 33,
      tier2: 'Tier 2',
      projected_compute: 10,
      variance: -8,
      variance_as_percentage: '(80.0%)'
    },
    {
      name: 'WWC',
      count: 19,
      tier1: 'Tier 6',
      avg_visit_count: 51,
      tier2: 'Tier 3',
      projected_compute: 11,
      variance: -8,
      variance_as_percentage: '(72.7%)'
    },
    {
      name: 'AND',
      count: 14,
      tier1: 'Tier 5',
      avg_visit_count: 22,
      tier2: 'Tier 1',
      projected_compute: 7,
      variance: -7,
      variance_as_percentage: '(100.0%)'
    },
    {
      name: 'BTB',
      count: 17,
      tier1: 'Tier 6',
      avg_visit_count: 36,
      tier2: 'Tier 2',
      projected_compute: 10,
      variance: -7,
      variance_as_percentage: '(70.0%)'
    },
    {
      name: 'GPF',
      count: 18,
      tier1: 'Tier 6',
      avg_visit_count: 45,
      tier2: 'Tier 3',
      projected_compute: 11,
      variance: -7,
      variance_as_percentage: '(63.6%)'
    },
    {
      name: 'HAP',
      count: 14,
      tier1: 'Tier 5',
      avg_visit_count: 29,
      tier2: 'Tier 1',
      projected_compute: 7,
      variance: -7,
      variance_as_percentage: '(100.0%)'
    },
    {
      name: 'KBF',
      count: 14,
      tier1: 'Tier 5',
      avg_visit_count: 26,
      tier2: 'Tier 1',
      projected_compute: 7,
      variance: -7,
      variance_as_percentage: '(100.0%)'
    },
    {
      name: 'KNE',
      count: 14,
      tier1: 'Tier 5',
      avg_visit_count: 26,
      tier2: 'Tier 1',
      projected_compute: 7,
      variance: -7,
      variance_as_percentage: '(100.0%)'
    },
    {
      name: 'LOP',
      count: 14,
      tier1: 'Tier 5',
      avg_visit_count: 16,
      tier2: 'Tier 1',
      projected_compute: 7,
      variance: -7,
      variance_as_percentage: '(100.0%)'
    },
    {
      name: 'MZR',
      count: 9,
      tier1: 'Tier 2',
      avg_visit_count: 29,
      tier2: 'Tier 1',
      projected_compute: 7,
      variance: -2,
      variance_as_percentage: '(28.6%)'
    },
    {
      name: 'PIN',
      count: 13,
      tier1: 'Tier 4',
      avg_visit_count: 30,
      tier2: 'Tier 1',
      projected_compute: 7,
      variance: -6,
      variance_as_percentage: '(85.7%)'
    },
    {
      name: 'PTM',
      count: 14,
      tier1: 'Tier 5',
      avg_visit_count: 30,
      tier2: 'Tier 1',
      projected_compute: 7,
      variance: -7,
      variance_as_percentage: '(100.0%)'
    },
    {
      name: 'PWD',
      count: 17,
      tier1: 'Tier 6',
      avg_visit_count: 42,
      tier2: 'Tier 3',
      projected_compute: 11,
      variance: -6,
      variance_as_percentage: '(54.5%)'
    },
    {
      name: 'SMY',
      count: 17,
      tier1: 'Tier 6',
      avg_visit_count: 42,
      tier2: 'Tier 3',
      projected_compute: 11,
      variance: -6,
      variance_as_percentage: '(54.5%)'
    },
    {
      name: 'TSL',
      count: 18,
      tier1: 'Tier 6',
      avg_visit_count: 46,
      tier2: 'Tier 3',
      projected_compute: 11,
      variance: -7,
      variance_as_percentage: '(63.6%)'
    },
    {
      name: 'CFD',
      count: 13,
      tier1: 'Tier 4',
      avg_visit_count: 21,
      tier2: 'Tier 1',
      projected_compute: 7,
      variance: -6,
      variance_as_percentage: '(85.7%)'
    },
    {
      name: 'CGF',
      count: 13,
      tier1: 'Tier 4',
      avg_visit_count: 23,
      tier2: 'Tier 1',
      projected_compute: 7,
      variance: -6,
      variance_as_percentage: '(85.7%)'
    },
    {
      name: 'FTH',
      count: 16,
      tier1: 'Tier 6',
      avg_visit_count: 32,
      tier2: 'Tier 2',
      projected_compute: 10,
      variance: -6,
      variance_as_percentage: '(60.0%)'
    },
    {
      name: 'LKO',
      count: 15,
      tier1: 'Tier 5',
      avg_visit_count: 37,
      tier2: 'Tier 2',
      projected_compute: 10,
      variance: -5,
      variance_as_percentage: '(50.0%)'
    },
    {
      name: 'RCR',
      count: 13,
      tier1: 'Tier 4',
      avg_visit_count: 18,
      tier2: 'Tier 1',
      projected_compute: 7,
      variance: -6,
      variance_as_percentage: '(85.7%)'
    },
    {
      name: 'SBY',
      count: 17,
      tier1: 'Tier 6',
      avg_visit_count: 43,
      tier2: 'Tier 3',
      projected_compute: 11,
      variance: -6,
      variance_as_percentage: '(54.5%)'
    },
    {
      name: 'WRW',
      count: 13,
      tier1: 'Tier 4',
      avg_visit_count: 30,
      tier2: 'Tier 1',
      projected_compute: 7,
      variance: -6,
      variance_as_percentage: '(85.7%)'
    },
    {
      name: 'DRO',
      count: 15,
      tier1: 'Tier 5',
      avg_visit_count: 32,
      tier2: 'Tier 2',
      projected_compute: 10,
      variance: -5,
      variance_as_percentage: '(50.0%)'
    },
    {
      name: 'GDC',
      count: 11,
      tier1: 'Tier 3',
      avg_visit_count: 23,
      tier2: 'Tier 1',
      projected_compute: 7,
      variance: -4,
      variance_as_percentage: '(57.1%)'
    },
    {
      name: 'KNT',
      count: 11,
      tier1: 'Tier 3',
      avg_visit_count: 29,
      tier2: 'Tier 1',
      projected_compute: 7,
      variance: -4,
      variance_as_percentage: '(57.1%)'
    },
    {
      name: 'MAD',
      count: 11,
      tier1: 'Tier 3',
      avg_visit_count: 21,
      tier2: 'Tier 1',
      projected_compute: 7,
      variance: -4,
      variance_as_percentage: '(57.1%)'
    },
    {
      name: 'MCB',
      count: 15,
      tier1: 'Tier 5',
      avg_visit_count: 40,
      tier2: 'Tier 2',
      projected_compute: 10,
      variance: -5,
      variance_as_percentage: '(50.0%)'
    },
    {
      name: 'NCB',
      count: 17,
      tier1: 'Tier 6',
      avg_visit_count: 61,
      tier2: 'Tier 4',
      projected_compute: 13,
      variance: -4,
      variance_as_percentage: '(30.8%)'
    },
    {
      name: 'NVI',
      count: 11,
      tier1: 'Tier 3',
      avg_visit_count: 24,
      tier2: 'Tier 1',
      projected_compute: 7,
      variance: -4,
      variance_as_percentage: '(57.1%)'
    },
    {
      name: 'PTC',
      count: 15,
      tier1: 'Tier 5',
      avg_visit_count: 55,
      tier2: 'Tier 3',
      projected_compute: 11,
      variance: -4,
      variance_as_percentage: '(36.4%)'
    },
    {
      name: 'SPT',
      count: 11,
      tier1: 'Tier 3',
      avg_visit_count: 16,
      tier2: 'Tier 1',
      projected_compute: 7,
      variance: -4,
      variance_as_percentage: '(57.1%)'
    },
    {
      name: 'STK',
      count: 14,
      tier1: 'Tier 5',
      avg_visit_count: 50,
      tier2: 'Tier 3',
      projected_compute: 11,
      variance: -3,
      variance_as_percentage: '(27.3%)'
    },
    {
      name: 'TAY',
      count: 12,
      tier1: 'Tier 4',
      avg_visit_count: 24,
      tier2: 'Tier 1',
      projected_compute: 7,
      variance: -5,
      variance_as_percentage: '(71.4%)'
    },
    {
      name: 'TKR',
      count: 15,
      tier1: 'Tier 5',
      avg_visit_count: 39,
      tier2: 'Tier 2',
      projected_compute: 10,
      variance: -5,
      variance_as_percentage: '(50.0%)'
    },
    {
      name: 'WYE',
      count: 12,
      tier1: 'Tier 4',
      avg_visit_count: 30,
      tier2: 'Tier 1',
      projected_compute: 7,
      variance: -5,
      variance_as_percentage: '(71.4%)'
    },
    {
      name: 'CFP',
      count: 11,
      tier1: 'Tier 3',
      avg_visit_count: 14,
      tier2: 'Tier 1',
      projected_compute: 7,
      variance: -4,
      variance_as_percentage: '(57.1%)'
    },
    {
      name: 'CMG',
      count: 13,
      tier1: 'Tier 4',
      avg_visit_count: 36,
      tier2: 'Tier 2',
      projected_compute: 10,
      variance: -3,
      variance_as_percentage: '(30.0%)'
    },
    {
      name: 'CPR',
      count: 11,
      tier1: 'Tier 3',
      avg_visit_count: 18,
      tier2: 'Tier 1',
      projected_compute: 7,
      variance: -4,
      variance_as_percentage: '(57.1%)'
    },
    {
      name: 'CWA',
      count: 15,
      tier1: 'Tier 5',
      avg_visit_count: 58,
      tier2: 'Tier 3',
      projected_compute: 11,
      variance: -4,
      variance_as_percentage: '(36.4%)'
    },
    {
      name: 'DBN',
      count: 14,
      tier1: 'Tier 5',
      avg_visit_count: 34,
      tier2: 'Tier 2',
      projected_compute: 10,
      variance: -4,
      variance_as_percentage: '(40.0%)'
    },
    {
      name: 'DCR',
      count: 15,
      tier1: 'Tier 5',
      avg_visit_count: 42,
      tier2: 'Tier 3',
      projected_compute: 11,
      variance: -4,
      variance_as_percentage: '(36.4%)'
    },
    {
      name: 'DVE',
      count: 14,
      tier1: 'Tier 5',
      avg_visit_count: 51,
      tier2: 'Tier 3',
      projected_compute: 11,
      variance: -3,
      variance_as_percentage: '(27.3%)'
    },
    {
      name: 'LGR',
      count: 14,
      tier1: 'Tier 5',
      avg_visit_count: 39,
      tier2: 'Tier 2',
      projected_compute: 10,
      variance: -4,
      variance_as_percentage: '(40.0%)'
    },
    {
      name: 'LIL',
      count: 14,
      tier1: 'Tier 5',
      avg_visit_count: 39,
      tier2: 'Tier 2',
      projected_compute: 10,
      variance: -4,
      variance_as_percentage: '(40.0%)'
    },
    {
      name: 'LKW',
      count: 10,
      tier1: 'Tier 2',
      avg_visit_count: 25,
      tier2: 'Tier 1',
      projected_compute: 7,
      variance: -3,
      variance_as_percentage: '(42.9%)'
    },
    {
      name: 'MAY',
      count: 10,
      tier1: 'Tier 2',
      avg_visit_count: 27,
      tier2: 'Tier 1',
      projected_compute: 7,
      variance: -3,
      variance_as_percentage: '(42.9%)'
    },
    {
      name: 'SCH',
      count: 11,
      tier1: 'Tier 3',
      avg_visit_count: 30,
      tier2: 'Tier 1',
      projected_compute: 7,
      variance: -4,
      variance_as_percentage: '(57.1%)'
    },
    {
      name: 'SGT',
      count: 11,
      tier1: 'Tier 3',
      avg_visit_count: 24,
      tier2: 'Tier 1',
      projected_compute: 7,
      variance: -4,
      variance_as_percentage: '(57.1%)'
    },
    {
      name: 'SMH',
      count: 14,
      tier1: 'Tier 5',
      avg_visit_count: 37,
      tier2: 'Tier 2',
      projected_compute: 10,
      variance: -4,
      variance_as_percentage: '(40.0%)'
    },
    {
      name: 'STR',
      count: 10,
      tier1: 'Tier 2',
      avg_visit_count: 24,
      tier2: 'Tier 1',
      projected_compute: 7,
      variance: -3,
      variance_as_percentage: '(42.9%)'
    },
    {
      name: 'WLW',
      count: 13,
      tier1: 'Tier 4',
      avg_visit_count: 40,
      tier2: 'Tier 2',
      projected_compute: 10,
      variance: -3,
      variance_as_percentage: '(30.0%)'
    }
  ]
};

export const AMCHARTS_WATERFALL_DATA = [
  {
    category: 'Net revenue',
    value: 8786,
    open: 0,
    stepValue: 8786,
    displayValue: 8786
  },
  {
    category: 'Cost of sales',
    value: 8786 - 2786,
    open: 8786,
    stepValue: 8786 - 2786,
    displayValue: 2786
  },
  {
    category: 'Operating expenses',
    value: 8786 - 2786 - 1786,
    open: 8786 - 2786,
    stepValue: 8786 - 2786 - 1786,
    displayValue: 1786
  },
  {
    category: 'Amortisation',
    value: 8786 - 2786 - 1786 - 453,
    open: 8786 - 2786 - 1786,
    stepValue: 8786 - 2786 - 1786 - 453,
    displayValue: 453
  },
  {
    category: 'Income from equity',
    value: 8786 - 2786 - 1786 - 453 + 1465,
    open: 8786 - 2786 - 1786 - 453,
    stepValue: 8786 - 2786 - 1786 - 453 + 1465,
    displayValue: 1465
  },
  {
    category: 'Operating income',
    value: 8786 - 2786 - 1786 - 453 + 1465,
    open: 0,
    displayValue: 8786 - 2786 - 1786 - 453 + 1465
  }
];

export const AMCHARTS_MULTI_SERIES_BAR_DATA = {
  Title: 'Microsoft Office 365 Optimisation',
  Y1_axis: '# of Licenses',
  Y2_axis: 'Annual Cost ($)',
  X_axis: ['Now', 'Optimised'],
  Values: [
    {
      category: 'Q1',
      value1: 100,
      value2: 200,
      value3: 300,
      series1DrillDownChartData: [
        { category: 'A', value1: 100 },
        { category: 'B', value1: 200 },
        { category: 'C', value1: 300 }
      ]
    },
    {
      category: 'Q2',
      value1: 120,
      value2: 210,
      value3: 310,
      series1DrillDownChartData: [
        { category: 'A', value1: 120 },
        { category: 'B', value1: 210 },
        { category: 'C', value1: 310 }
      ]
    },
    {
      category: 'Q3',
      value1: 130,
      value2: 220,
      value3: 320,
      series1DrillDownChartData: [
        { category: 'A', value1: 130 },
        { category: 'B', value1: 220 },
        { category: 'C', value1: 320 }
      ]
    },
    {
      category: 'Q4',
      value1: 140,
      value2: 230,
      value3: 330,
      series1DrillDownChartData: [
        { category: 'A', value1: 140 },
        { category: 'B', value1: 230 },
        { category: 'C', value1: 330 }
      ]
    }
  ],
  Textual_Insight: 'Optimising your Microsoft Office 365 licenses allocation will save you $0.9M annually'
};

export const AMCHARTS_SANKEY_DATA = {
  id: 'Total_Over_Provisioned_Assets',
  icon: '',
  info: 'Tech provisioning analysis across Tiers and organizations',
  Title: 'Technology provisioning analysis across Tiers and organizations',
  category: 'Benchmark',
  isEnabled: true,
  valueLabel: null,
  chartType: 'sankeychart',
  value: {
    node_categories: ['category1', 'category2', 'category3', 'category4'],
    node_values: [
      { Total: 2764 },
      { 'E5*': 2628 },
      { 'F3(Teams Online + Sec)*': 136 },
      { E5: 1453 },
      { 'F3(Teams Dekstop + Sec)': 811 },
      { 'F3(Teams Online + Sec)': 227 },
      { Deactivate: 273 },
      { 'Adv Practice Providers': 543 },
      { 'Clinical Svc Reps': 752 },
      { 'Revenue Management': 235 },
      { 'Patient Svc Reps': 457 },
      { Physicians: 154 },
      { Others: 623 }
    ],
    links: [
      { from: 'Total', to: 'E5*', thickness: 2628 },
      { from: 'Total', to: 'F3(Teams Online + Sec)*', thickness: 136 },
      { from: 'E5*', to: 'E5', thickness: 1449 },
      { from: 'E5*', to: 'F3(Teams Dekstop + Sec)', thickness: 780 },
      { from: 'E5*', to: 'F3(Teams Online + Sec)', thickness: 216 },
      { from: 'E5*', to: 'Deactivate', thickness: 183 },
      { from: 'F3(Teams Online + Sec)*', to: 'E5', thickness: 4 },
      { from: 'F3(Teams Online + Sec)*', to: 'Deactivate', thickness: 90 },
      { from: 'F3(Teams Online + Sec)*', to: 'F3(Teams Dekstop + Sec)', thickness: 31 },
      { from: 'F3(Teams Online + Sec)*', to: 'F3(Teams Online + Sec)', thickness: 11 },
      { from: 'E5', to: 'Adv Practice Providers', thickness: 233 },
      { from: 'E5', to: 'Clinical Svc Reps', thickness: 379 },
      { from: 'E5', to: 'Revenue Management', thickness: 226 },
      { from: 'E5', to: 'Patient Svc Reps', thickness: 221 },
      { from: 'E5', to: 'Physicians', thickness: 36 },
      { from: 'E5', to: 'Others', thickness: 358 },
      { from: 'F3(Teams Dekstop + Sec)', to: 'Adv Practice Providers', thickness: 205 },
      { from: 'F3(Teams Dekstop + Sec)', to: 'Clinical Svc Reps', thickness: 279 },
      { from: 'F3(Teams Dekstop + Sec)', to: 'Patient Svc Reps', thickness: 174 },
      { from: 'F3(Teams Dekstop + Sec)', to: 'Physicians', thickness: 53 },
      { from: 'F3(Teams Dekstop + Sec)', to: 'Revenue Management', thickness: 3 },
      { from: 'F3(Teams Dekstop + Sec)', to: 'Others', thickness: 97 },
      { from: 'F3(Teams Online + Sec)', to: 'Adv Practice Providers', thickness: 66 },
      { from: 'F3(Teams Online + Sec)', to: 'Clinical Svc Reps', thickness: 56 },
      { from: 'F3(Teams Online + Sec)', to: 'Patient Svc Reps', thickness: 28 },
      { from: 'F3(Teams Online + Sec)', to: 'Physicians', thickness: 39 },
      { from: 'F3(Teams Online + Sec)', to: 'Others', thickness: 38 },
      { from: 'Deactivate', to: 'Patient Svc Reps', thickness: 34 },
      { from: 'Deactivate', to: 'Clinical Svc Reps', thickness: 38 },
      { from: 'Deactivate', to: 'Physicians', thickness: 26 },
      { from: 'Deactivate', to: 'Adv Practice Providers', thickness: 39 },
      { from: 'Deactivate', to: 'Revenue Management', thickness: 6 },
      { from: 'Deactivate', to: 'Others', thickness: 130 }
    ]
  },
  footers: [],
  xaxisTitle: '',
  yaxisTitle: '',
  xaxisValueLabel: null,
  yaxisValueLabel: null,
  insightText: [
    'Piedmont has the highest Over Provisioned issue with 59 clinics, highlighting potential inefficiencies in resource allocation.',
    'Piedmont has the highest Under Provisioned issue with 11 clinics, indicating specific provisioning challenges.'
  ]
};

export const AMCHARTS_STACKED_COLUMN_DATA = {
  id: 'Tier_Across_Organization',
  icon: '',
  info: 'Tier spread across organization',
  Title: 'Tier spread across organization',
  category: 'Benchmark',
  isEnabled: true,
  valueLabel: null,
  chartType: 'stackedbarchart',
  value: [
    {
      category: 'Prisma',
      'Tier 3': 4,
      'Tier 1': 11
    },
    {
      category: 'Piedmont',
      'Tier 4': 8,
      'Tier 1': 19,
      'Tier 3': 24,
      'Tier 2': 21
    },
    {
      category: 'Beaumont',
      'Tier 2': 10,
      'Tier 1': 12,
      'Tier 3': 5,
      'Tier 4': 1
    },
    {
      category: 'UH',
      'Tier 2': 5,
      'Tier 1': 12,
      'Tier 3': 1
    }
  ],
  footers: [],
  xaxisTitle: '',
  yaxisTitle: '',
  xaxisValueLabel: null,
  yaxisValueLabel: null,
  insightText: ''
};
